@import "../../assets/scss/variables.scss";

.info_back {
  width: fit-content;
  background-color: $note-lite-bg-color;
  border-radius: 10px;
  max-width: 420px;
}

.info_text {
  font-family: $outfit;
  font-style: normal;
  font-weight: 400;
  font-size: $d-font-12;
  line-height: 15px;

  color: $black-color;
  
}
