@import "../../../../../assets/scss/variables.scss";

* {
  font-family: "Poppins" sans-serif;
}

.content {
  height: 750px;
}

.trialText {
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  color: #0075f3;
}

.fileUpload {
  width: 300px;
}

.mandatory {
  color: $danger
}

.headingText {
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
}

.infoForm {
  // width: 1200px;
  & > p {
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #858585;
  }

  .label {
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
  }

  .selector {
    height: 46px;
    width: 300px;
    padding: 10px;
    border-radius: 8px;
    background-color: #f7f7f7;
    outline: none;
    border: none;
    cursor: pointer;

    &:focus {
      border: 1px solid #b0d4fb;
    }
  }

  .selector option {
    padding: 5px;
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    border: 1px solid #b0d4fb;
  }

  .ackText {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
  }

  .genderSelector input[type="radio"] {
    opacity: 0;
    position: fixed;
    width: 0;
  }

  .genderSelector label {
    border: 1px solid #e8e8e8;
    background-color: #ffffff;
    color: #858585;
    width: 145px;
    font-weight: 400;
    font-size: 14px;
    display: inline-block;
    height: 46px;
    border-radius: 8px;
    outline: none;
    font-weight: 400;
    opacity: 0.8;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      cursor: pointer;
    }
  }

  .genderSelector input[type="radio"]:checked + label {
    border: 1px solid #0075f3;
    background-color: #0075f30f;
    color: #0075f3;
    opacity: 0.8;
    &::after {
      content: "✓";
    }
  }

  .buttonGroup {
    display: flex;
    width: 54%;
    justify-content: space-between;

    .submitButton {
      background-color: #0075f3;
      width: 192px;
      height: 45px;
      left: calc(50% - 158px / 2 - 642px);
      top: calc(50% - 55px / 2 + 344px);
      border-radius: 8px;
      border: none;
      outline: none;
      opacity: 0.8;
      color: #ffffff;
    }
  }

  .countrySelector {
    height: 70px;
  }

  .disabledSubmitButton {
    background-color: gray;
    width: 192px;
    height: 45px;
    left: calc(50% - 158px / 2 - 642px);
    top: calc(50% - 55px / 2 + 344px);
    border-radius: 8px;
    border: none;
    outline: none;
    opacity: 0.8;
    color: #ffffff;
    padding: 5px 0px;
    cursor: not-allowed;
  }
}
