@import "../../../assets/scss/variables.scss";

.background {
  height: 100vh;
  background: $backgroundColor;
  overflow: auto;
}

.overallheadingText {
  font-family: $firaSans;
  font-size: $font-16;
  font-weight: 500;
  text-align: left;
  color: $primaryColor;
}

.ActivationCard {
  width: 100%;
  border-radius: 10px;
  box-shadow: $CardboxShadow;
  background-color: $white;
}

.cardAnimation {
  animation: $annimationName 0.5s ease;
}

.cardHeader {
  box-shadow: $CardboxShadow;
  background-color: $cardHeaderColor;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.headingText {
  font-family: $firaSans;
  font-size: $font-14;
  font-weight: 500;
  text-align: left;
  color: $primaryColor;
}

.upload {
  font-size: $font-14;
  text-align: center;
  color: $accentColor;
  font-weight: 600;
  cursor: pointer;
}

.upload:hover {
  color: $accentHoverColor;
  transition: color 0.2s;
}

.required:after {
  content: " *";
  color: $error;
  padding-left: 5px;
}

.descText {
  font-size: $font-12;
  text-align: left;
  color: $secondaryColor;
}

.fileType {
  visibility: hidden;
  display: none;
}

.uploadLabel {
  font-size: $font-14;
  font-weight: 400;
  text-align: left;
  color: $primaryColor;
}

.fileURL {
  font-size: $font-12;
  text-align: left;
  color: $secondaryColor;
}

.closeSvg {
  fill: $primaryColor;
  cursor: pointer;
}
