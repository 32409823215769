@import "../../assets/scss/variables.scss";

.onBoardingSideBar {
  height: 100vh;
  box-shadow: 5px 0 15px 0 rgba(0, 0, 0, 0.16);
  color: $white;
}
.stepNumberWrapper {
  width: 45px;
  height: 45px;
  border-radius: 50%;
}

.stepNumber {
  font-size: $font-18;
  font-weight: 600;
  text-align: left;
  color: $white;
}

.stepText {
  font-size: $font-18;
  text-align: left;
  opacity: 0.5;
}

.activeStepText {
  font-size: $font-18;
  font-weight: 600;
  text-align: left;
  transition: font-size 0.5s;
}

.unVisited {
  width: 20px;
  height: 20px;
  border: 1px solid $white;
  opacity: 0.5;
  border-radius: 4px;
}

.currentStep {
  width: 20px;
  height: 20px;
  background: $white;
  border-radius: 4px;
}

.stepCompleted {
  width: 20px;
  height: 20px;
  background: $success;
  border-radius: 4px;
  position: relative;
}

.stepCompletedText {
  font-size: $font-18;
  text-align: left;
  color: $success;
  opacity: 0.8;
  transition: color 0.5s;
}

.logo {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: 5%;
}
