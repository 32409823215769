@import "../../../../../assets/scss/variables.scss";

.key {
  font-family: $outfit;
  font-style: normal;
  font-weight: 400;
  font-size: $d-font-12;
  line-height: 15px;

  color: $text-secondary-color;
}

.value {
  font-family: $outfit;
  font-style: normal;
  font-weight: 400;
  font-size: $d-font-14;
  line-height: 18px;

  color: $black-color;
}
