@import "../../../../assets/scss/variables.scss";

.headingText {
  font-size: $font-18;
  font-weight: 500;
  text-align: center;
  color: $primaryColor;
}
.subText {
  font-size: $font-14;
  text-align: center;

  color: $secondaryColor;
}
