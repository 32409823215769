@import "../../assets/scss/variables.scss";

.tableHeaderWrapper {
  width: 100%;
  background-color: $background-white-color;
}

.tableHeader {
  font-size: $d-font-14;
  font-weight: 500;
  text-align: left;
  color: $text-secondary-color;
  border-bottom: 0;
}

.table {
  width: 100%;
  border: none !important;
}

tr {
  border: none !important;
  border-bottom: 1px solid #ebebeb !important;
}

thead {
  border: none !important;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.13);
}

.tableOverlay {
  width: 100%;
  overflow-x: auto; //disable auto scroll, see BOS216
}

.card {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 5px 15px 0 rgba(219, 219, 219, 0.16);
  background-color: $background-white-color;
}

.cardAnimation {
  animation: $annimationName 0.5s ease;
}

.cardHeader {
  box-shadow: $CardboxShadow;
  background-color: $background-white-color;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.noRecordTitle {
  font-family: $outfit;
  font-style: normal;
  font-weight: 600;
  font-size: $d-font-18;
  line-height: 23px;
  /* identical to box height */

  text-align: center;

  color: $black-color;
}

.noRecordBody {
  font-family: $outfit;
  font-style: normal;
  font-weight: 400;
  font-size: $d-font-14;
  line-height: 18px;
  text-align: center;
  margin-top: 7px;
  max-width: 250px;

  color: #666666;
}

.bottomOverflow {
  --bottom-mask-size: 50px !important;
}

.topOverflow {
  --top-mask-size: 25px !important;
}

.tbodyScroll {
  max-height: 40vh;
  display: block;
  overflow: auto;
}
