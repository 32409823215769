@import "../../../assets/scss/variables.scss";

* {
  font-family: "poppins", sans-serif;
}

.zpinBox {
  width: 100%;
  height: 204px;
  left: 680px;
  top: 610px;
  padding: 25px 20px;
  color: #ffffff;
  margin-top: 20px;

  background: linear-gradient(
    258deg,
    #3d62ed -30.81%,
    rgba(64, 181, 193, 0.83) 98.21%
  );
  border-radius: 10px;
  & > h5 {
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 39px;
  }

  & > p {
    font-size: $font-14;
    max-width: 90%;
  }
}

.createZpinButton {
  outline: none;
  border: none;
  background: rgba(255, 255, 255, 0.87);
  width: 152px;
  height: 40px;
  border-radius: 5px;
  color: #004c9e;
  font-family: Poppins;
  font-size: $font-14;
  font-weight: 400;
  padding: 9px 12px;
}

.disabledCreateButton {
  outline: none;
  border: none;
  background: Gray;
  width: 152px;
  height: 40px;
  border-radius: 5px;
  color: whitesmoke;
  font-family: Poppins;
  font-size: $font-14;
  font-weight: 400;
  padding: 9px 12px;
  opacity: 0.5;
}

.submitZpinButton {
  background: rgba(255, 255, 255, 0.27);
  border-radius: 8px;
  outline: none;
  border: none;
  color: #ffffff;
  font-family: Poppins;
  font-size: $font-14;
  font-weight: 400;
  padding: 9px 12px;
  width: 182px;
  height: 40px;
}

.zpinIntro {
  display: flex;
  justify-content: space-between;
  position: relative;
  & > h5 {
    margin-top: 8px;
  }
}

.newTag {
  background-color: #56d4ff;
  position: absolute;
  border-radius: 5px 0px 0px 5px;
  left: 90%;
  width: 65px;
  height: 30px;
  font-size: $font-12;
  padding: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}
