@import "../../assets/scss/variables.scss";

.dropDownHeader {
  font-size: $font-14;
  font-weight: 500;
  color: $accentColor;
  cursor: pointer;
}

.dropdown:focus {
  outline: none;
}

.active {
  color: $accentColor;
  display: block;
  width: 100%;
  padding: 10px;
  font-weight: 600;
  cursor: pointer;
  text-align: left;
  white-space: nowrap;
  border: 0;
  font-size: $font-14;
  border-radius: 8px;
}

.dropdownLink {
  background: $white;
  color: $secondaryColor;
  display: block;
  width: 100%;
  padding: 10px;
  cursor: pointer;
  font-weight: 400;
  text-align: left;
  white-space: nowrap;
  border: 0;
  font-size: $font-14;
}

.active:hover {
  color: $accentColor;
  cursor: pointer;
  text-decoration: none;
  background: $linkHoverBackground;
}

.dropdownLink:hover {
  color: $accentColor;
  text-decoration: none;
  background: $linkHoverBackground;
  border-radius: 8px;
  cursor: pointer;
}
