@import "../../../../assets/scss/variables.scss";

.headingText {
  font-size: $font-16;
  font-weight: 600;
  text-align: center;
  color: $primaryColor;
}

.subText {
  text-align: center;

  font-family: $outfit;
  font-style: normal;
  font-weight: 400;
  font-size: $d-font-14;
  line-height: 18px;
  /* identical to box height */

  color: $text-secondary-color;
}

.cancelText {
  font-size: $font-12;
  color: $error;
  cursor: pointer;
  text-decoration: underline;
}
