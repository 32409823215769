@import "../../assets/scss/variables.scss";

.wrapper {
  height: 100%;
  width: 500px;
  margin: 0 auto;
}

.emptyStateHeadingText {
  font-family: $firaSans;
  font-size: $font-18;
  font-weight: 500;
  text-align: center;
  color: $primaryColor;
}

.emptyStateBodyText {
  font-size: $font-14;
  text-align: center;
  color: $secondaryColor;
}
