@import "../../../../../assets/scss/variables.scss";

.headingText {
  font-family: $outfit;
  font-style: normal;
  font-weight: 400;
  font-size: $d-font-12;
  line-height: 15px;

  color: $black-color;
}

.questionText {
  font-family: $outfit;
  font-style: normal;
  font-weight: 700;
  font-size: $d-font-14;
  line-height: 18px;

  color: $black-color;
}

.answerText {
  font-family: $outfit;
  font-style: normal;
  font-weight: 400;
  font-size: $d-font-14;
  line-height: 18px;
  margin-top: 5px;
  color: #5f5f5f;
}

.headingTitle {
  font-family: $outfit;
  font-style: normal;
  font-weight: 600;
  font-size: $d-font-16;
  line-height: 20px;
  margin-bottom: 31px;

  color: $black-color;
}

.answerContainer {
  background: $background-white-color;
  border-radius: 10px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;

  min-height: 185px;
  width: 33%;
}
