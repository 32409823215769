@import "../../../../assets/scss/variables.scss";

.headingText {
  font-size: $font-16;
  font-weight: 600;
  text-align: center;
  color: $primaryColor;
}

.subText {
  text-align: center;
  font-family: $outfit;
  font-style: normal;
  font-weight: 400;
  font-size: $d-font-12;
  line-height: 15px;

  color: $text-secondary-color;
}

.spanText {
  font-size: $font-14;
  font-weight: 500;
  color: $primaryColor;
}

.cancelText {
  font-size: $font-12;
  color: $error;
  cursor: pointer;
  text-decoration: underline;
}

.uploadDocsText {
  font-size: $font-12;
  font-weight: 500;
  color: $primaryColor;
}
.uploadDocsText:hover {
  text-decoration: none;
  color: $accentColor;
}
