@import "../../../../assets//scss/variables.scss";

* {
  font-family: "poppins" sans-serif;
}

.serviceInfoCard {
  background-color: #ffffff;
  box-sizing: border-box;
  border: 1px solid #dddddd;
  border-radius: 8px;
  width: 350px;
  height: 300px;
  padding: 20px;
  margin: 5px;
}

.serviceName {
  font-weight: 600;
  font-size: $font-16;
  line-height: 18px;
}

.serviceDesc {
  font-weight: 400;
  font-size: $font-14;
  line-height: 18px;
  color: #454545;
}

.avalCreds {
  font-weight: 600;
  font-size: $font-14;
  line-height: 2px;
  color: black;
}

.creditsInfo {
  font-weight: 600;
  font-size: 28px;
  color: #000000;
  font-style: normal;
}

.trialButton {
  width: 140px;
  height: 40px;
  background-color: #ebf4fe;
  font-weight: 400;
  font-size: $font-14;
  line-height: 5px;
  outline: none;
  border: none;
  border-radius: 5px;
  color: #0075f3;
}

.browseButtonContainer {
  height: 100%;

  & > p {
    font-size: 20px;
    font-weight: 400;
    line-height: 33px;
    letter-spacing: -0.01em;
    text-align: center;
    color: #85bdf9;
  }

  &:hover {
    outline: none;
    text-decoration: none;
    color: #85bdf9;
  }
}

.browseButton {
  height: 40%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 150px;
  font-weight: 100;
  outline: none;
  border: none;
  background-color: #ffffff;
  color: #85bdf9;

  &:focus {
    outline: none;
    border: none;
  }
}
