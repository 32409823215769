@import "../../../../assets//scss/variables.scss";

.demoInfo {
  background-color: #cdd8e4;
  box-sizing: border-box;
  border: 1px solid #dddddd;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 400;
  width: 260px;
  min-height: 260px;
  padding: 20px;
  margin: 5px;
  margin-top: 70px;
}
