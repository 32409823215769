@import "../../assets/scss/variables.scss";

.btn {
  height: 40px;
  text-align: center;
  padding: 0 15px;
  // box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.16);

  border-radius: 10px;
}

.btnText {
  font-weight: 500;
  text-align: center;
}

.btn:disabled {
  opacity: 0.5;
  cursor: no-drop;
}
