@import "../../../assets/scss/variables.scss";

.overallheadingText {
  font-family: $firaSans;
  font-size: $font-16;
  font-weight: 500;
  text-align: left;
  color: $primaryColor;
}

// .numberOfRows {
//   font-size: $font-12;
//   text-align: left;
//   color: $secondaryColor;
// }

.clearSelection {
  font-size: $font-12;
  text-align: left;
  color: $secondaryColor;
  cursor: pointer;
}

.clearSelection:hover {
  color: $error;
  transition: color 0.2s;
}

.downloadCSV,
.downloadCSV:not([href]) {
  font-family: $outfit;
  font-style: normal;
  font-weight: 600;
  font-size: $d-font-12 !important;
  line-height: 15px;

  color: $primary-color !important;
  text-decoration: none;
  cursor: pointer;
}

.downloadCSV:hover,
.downloadCSV:hover:not([href]) {
  text-decoration: none;
  color: $accentHoverColor;
  transition: color 0.3s;
}

.infoIcon {
  fill: $secondaryColor;
}

.InfoIconBack {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: transparent;
  cursor: default;
}

.InfoIconBack:hover {
  background: $backgroundColor;
  .infoIcon {
    fill: $secondaryColor;
  }
}

.backText {
  font-size: $font-14;
  color: $secondaryColor;
}

.rotateLeft {
  cursor: pointer;
  transform: rotate(+90deg);
}

.rotateRight {
  cursor: pointer;
  transform: rotate(-90deg);
}

.pageNumberText {
  font-size: $font-14;
  text-align: left;
  color: $primaryColor;
}

.pageNumberInActive {
  width: 35px;
  height: 35px;
  border-radius: 8px;
  border: solid 1px $secondaryColor;
  background-color: $white;
  cursor: pointer;
}

.pageNumberActive,
.pageNumberInActive:hover {
  width: 35px;
  height: 35px;
  border: 0;
  cursor: pointer;
  border-radius: 8px;
  background-color: $accentColor;
  transition: background-color 0.5s;
  .pageNumberText {
    color: $white;
    transition: color 0.2s;
  }
}

input[type="radio"] {
  -ms-transform: scale(1.3); /* IE 9 */
  -webkit-transform: scale(1.3); /* Chrome, Safari, Opera */
  transform: scale(1.3);
  margin: 4px;
}

.selectedRadio {
  color: $accentColor;
}

.textRadio {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;
}

.filterByText {
  font-weight: 600;
  font-family: "Poppins" sans-serif;
}

.searchId {
  width: 100%;
  height: 40px;
  outline: none;
  box-sizing: border-box;
  border: 1px solid $cardHeaderColor;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 400;
  font-family: $outfit;
  line-height: 18px;
  padding: 12px 20px 12px 40px;
  background-image: url("./../../../assets/images/search.svg");
  background-position: 12px 12px;
  background-repeat: no-repeat;
}

.card {
  width: 100%;
  border: 1px solid #dddcdd;
  border-radius: 16px;
}

.resultSetDescription {
  font-family: $outfit;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0px;
  margin-block: 12px;
  color: $text-secondary-color;
}

.emptyResultSet {
  border-radius: 16px;
  background-color: rgba(0, 117, 243, 0.05);
}

.formLabel {
  font-family: $outfit;
  font-style: normal;
  font-weight: 500;
  font-size: $d-font-12;
  line-height: 15px;
  color: $black-color;
}

.totalReportCount {
  font-family: $outfit;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  color: $text-secondary-color;
}

.resultSetText {
  font-family: $outfit;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}

.resultTable {
  width: 100%;
  border-radius: 16px;
  display: block;
  overflow-x: scroll;
  white-space: nowrap;
}

.resultTableHeader {
  color: black;
  box-shadow: none;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  height: 80px;
  font-family: $outfit;
  background-color: rgba(0, 117, 243, 0.05);
}

.resultTableRow {
  font-family: $outfit;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  height: 68px;
  gap: 48px;
}

.resultTableEvenRow {
  background: #f7f7f7;
}

.cardAnimation {
  animation: $annimationName 0.5s ease;
}

.headingText {
  font-family: $firaSans;
  font-size: $font-14;
  font-weight: 500;
  text-align: left;
  color: $primaryColor;
}

.cardHeader {
  box-shadow: $CardboxShadow;
  background-color: $background-white-color;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.tableOverlay {
  width: 100%;
  overflow-x: hidden; //disable auto scroll, see BOS216
}

.tableHeaderWrapper {
  width: 100%;
  background-color: $background-white-color;
}

.tableBody {
  font-size: $font-14;
  text-align: left;
  color: $primaryColor;
  word-wrap: break-word;
}

.date {
  width: 14%;
  padding: 20px;
}

.id {
  width: 14%;
  padding: 20px;
}

.product_name {
  width: 16%;
  padding: 20px;
}

.version {
  width: 9%;
  padding: 20px;
}

.environment {
  width: 11%;
  padding: 20px;
}

.status,
.reason_code {
  width: 10%;
  padding: 20px;
  //white-space: nowrap;
}

.response_code {
  width: 14%;
  padding: 20px;
  //white-space: nowrap;
}

@media screen and (max-width: 850px) {
  .tableOverlay {
    width: 100%;
    overflow-x: auto;
  }

  .date {
    width: 120px;
    padding: 20px;
  }

  .id {
    width: 250px;
    padding: 20px;
  }

  .product_name {
    width: 250px;
    padding: 20px;
  }

  .version,
  .environment,
  .status,
  .reason_code {
    width: 120px;
    padding: 20px;
    white-space: nowrap;
  }

  .response_code {
    width: 180px;
    padding: 20px;
    white-space: nowrap;
  }
}

@media (min-width: 750px) and (max-width: 1150px) {
  .resetContainer {
    //padding: none;
    align-items: center;
    justify-content: center;
    // flex-direction: column;
  }

  .clearSelection {
    margin-bottom: unset;
    padding-bottom: 10px;
  }

  .clearSelection {
    margin-bottom: 15px;
  }
}

@media (max-width: 420px) {
  .resetContainer {
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .clearSelection {
    margin-bottom: unset;
  }

  .clearSelection {
    margin-bottom: 15px;
    padding-bottom: 10px;
  }
}
.toDateContainer {
  margin-left: 10px;
}

@media (min-width: 320px) and (max-width: 1350px) {
  .datesContainer {
    flex-direction: column;
  }
  .toDateContainer {
    margin-left: unset;
  }
}

@media (max-width: 420px) {
}

.divider {
  border: 1px solid #ebebeb;
  margin-top: 30px;
  margin-bottom: 30px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

.reportContainer {
  overflow-y: auto;
  -webkit-mask-image: linear-gradient(
    to bottom,
    transparent 0,
    black 0,
    black calc(100% - var(--bottom-mask-size, 0)),
    transparent 100%
  );
  mask-image: linear-gradient(
    to bottom,
    transparent 0,
    black 0,
    black calc(100% - var(--bottom-mask-size, 0)),
    transparent 100%
  );
  height: 100vh;
}

.reportTableBody {
  max-height: 70vh;
  display: block;
  overflow: auto;
}
