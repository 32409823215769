@import "../../../../assets/scss/variables.scss";

.overlay {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 99;
  background-color: rgba(38, 50, 56, 0.7);
  transition: background-color 0.3s;
  animation: fade 0.3s ease-in-out;

  .popUpContent {
    width: 650px;
    border-radius: 10px;
    box-shadow: 0 0 25px 0 rgba(122, 106, 158, 0.25);
    background-color: $background-white-color;
    height: 650px;
  }
}

.cancelButton {
  background-color: $background-white-color;
  color: black;
  border: none;
  outline: none;
  opacity: 0.8;
  font-size: 24px;
}

.card_title_bold_text {
  font-family: "Outfit";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: #000000;
}

.tableHeaderBack {
  background-color: $background-white-color;
}

.tableHeaderText {
  font-family: "Outfit";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: #a0a2a5;
}

.tableWrapper {
  display: block;
  min-height: 400px;
  max-height: 450px;
  overflow: auto;
}

.tableBody {
  font-size: $font-14;
  padding: 20px 15px 1px;
  text-align: left;
  color: $primaryColor;
  border-bottom: 1px solid $border-color;
}
.productName {
  font-size: $font-16;
}
.back {
  font-size: $font-14;
  color: $secondaryColor;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.warningMessage {
  font-size: $font-14;
  font-weight: 500;
  color: $primaryColor;
}

.rotateArrowRightLongDescription {
  transform: rotate(-90deg);
  transition: transform 0.5s;
}

.longDescription {
  padding: 20px 30px;
  width: 100%;
  height: 100%;
  background: $white;
  color: $secondaryColor;
  font-size: $font-14;
}
.product_name {
  padding: 15px 15px 15px 25px;
  width: 250px;
  word-break: break-word;
}

.least {
  padding: 15px 15px 15px 15px;
  width: 100px;
  word-break: break-word;
}

.tableBodyText {
  font-size: $font-14;
  line-height: 2.14;
  letter-spacing: normal;
  text-align: left;
  color: #414141;
  word-break: break-all;
}

.tableBodyforPrice {
  font-size: $font-14;
  padding: 20px 15px 5px 80px;
  text-align: right;
  color: $primaryColor;
  border-bottom: 1px solid $border-color;
}
