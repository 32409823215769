@import "../../../../../assets/scss/variables.scss";

.headingText {
  font-size: $font-16;
  font-weight: 600;
  text-align: center;
  color: $primaryColor;
}

.subText {
  font-size: $font-12;
  text-align: center;
  line-height: 21px;
  color: $secondaryColor;
}

.discardText {
  font-size: $font-12;
  color: $secondaryColor;
  cursor: pointer;
}

.discardText:hover {
  color: $error;
  transition: color 0.3s ease-in;
}

.unsavedSettingName {
  font-size: $font-12;
  color: $secondaryColor;
  font-weight: 500;
  text-align: center;
}
