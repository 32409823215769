@import "../../../../assets/scss/variables.scss";

.headingText {
  font-size: $font-16;
  font-weight: 600;
  text-align: center;
  color: $primaryColor;
}

.noteLabel {
  font-family: $outfit;
  font-style: normal;
  font-weight: 600;
  font-size: $d-font-16;
  line-height: 20px;
  padding-bottom: 4px;

  color: $primary-color;
}

.noteText {
  font-family: $outfit;
  font-style: normal;
  font-weight: 400;
  font-size: $d-font-12;
  line-height: 15px;

  color: #484848;
}

.note {
  background: $note-bg-color;
  border-radius: 10px;
  padding: 1.5em;
}
