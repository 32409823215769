@import "../../../assets/scss/variables.scss";

.authActionsBackground {
  background: $white;
  height: 100%;
}

.text {
  font-size: $font-12;
  text-align: center;
  color: $secondaryColor;
}

.text2 {
  font-size: $font-12;
  text-align: center;
  color: $accentColor;
}

.signUpText {
  font-size: $font-14;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  color: $accentColor;
}

.signUpText:hover {
  text-decoration: none;
  color: $accentHoverColor;
  transition: color 0.2s;
}

.forgotPasswordText {
  font-size: $font-12;
  font-weight: 600;
  text-align: left;
  color: $accentColor;
  cursor: pointer;
}

.forgotPasswordText:hover {
  text-decoration: none;
  color: $accentHoverColor;
  transition: color 0.2s;
}

.formWidth {
  width: 300px;
}

.eye {
  object-fit: contain;
  cursor: pointer;
  position: absolute;
  right: 10px;
  margin-top: -4px;
}

.visitBtn {
  border: none;
  outline: 0 !important;
  font-size: 14px;
  cursor: pointer;
  color: $secondaryColor;
  background-color: $white;

  &:hover {
    color: $accentColor;
  }
}

@media screen and (max-width: 1200px) {
  .authActionsBackground {
    height: 100vh;
  }
}

.backButton {
  border-radius: 100%;
  background: linear-gradient(180deg, #24a5c6 0%, #2d89ec 100%);
  color: white;
  height: 45px;
  width: 30px;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    text-decoration: none;
    color: white;
  }
}
