@import "../../../../../assets/scss/variables.scss";

.info_back {
  width: fit-content;
  background-color: $note-bg-color;
  border-radius: 10px;
}

.info_text {
  font-family: $outfit;
  font-style: normal;
  font-weight: 400;
  font-size: $d-font-14;
  line-height: 18px;

  color: $primary-color;
}
