@import "../../../../assets/scss/variables.scss";

.rotate {
  transform: rotate(-90deg);
}

.loadingCard {
  width: 100%;
  height: 100%;
  background: $white;
  border-radius: 8px;
  box-shadow: $CardboxShadow;
}

.card {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background: $background-white-color;
  box-shadow: $CardboxShadow;
  margin-top: 0;
}

.cardAnimation {
  animation: $annimationName 0.5s;
}

.cardHeader {
  background-color: $background-white-color;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.productLevelSetting {
  box-shadow: 0px 10px 10px -10px rgba(0, 0, 0, 0.13);
}

.headingText {
  font-family: $outfit;
  font-style: normal;
  font-weight: 600;
  font-size: $d-font-14;
  line-height: 18px;

  align-items: center;
  text-align: center;

  color: $black-color;
}

.keyWidth {
  flex-basis: 30%;
}

.valueWidth {
  flex-basis: 70%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.key {
  font-family: $outfit;
  font-style: normal;
  font-weight: 400;
  font-size: $d-font-14;
  line-height: 18px;

  color: $text-secondary-color;
}

.value {
  font-family: $outfit;
  font-style: normal;
  font-weight: 400;
  font-size: $d-font-14;
  line-height: 18px;

  color: $black-color;
}

.serviceLevelSettings {
  border-radius: 50px;
  // border: solid 1px $secondaryColor;
  background: $lite-accent-color;
}

.svgColor {
  fill: $lite-accent-color;
  cursor: pointer;
}

.serviceLevelSettings:hover {
  background-color: $linkHoverBackground;
  border: 1px solid $linkHoverBackground;

  .serviceName {
    color: $accentColor;
  }

  .svgColor {
    fill: $accentColor;
  }
}

.addedService {
  border-radius: 50px;
  background-color: $lite-accent-color;
  border: 1px solid $lite-accent-color;
  .serviceName {
    color: $accentColor;
  }
}

.addServiceText {
  font-size: $font-12;
  color: $accentColor;
  cursor: pointer;
}

.addServiceText:hover {
  color: $accentHoverColor;
  transition: color 0.2s;
  text-decoration: none;
}

.serviceName {
  font-family: $outfit;
  font-style: normal;
  font-weight: 400;
  font-size: $d-font-14;
  line-height: 18px;

  text-align: center;

  color: $primary-color;
}
.appSettings {
  font-size: $font-14;
  text-align: center;
  color: $accentColor;
  font-weight: 500;
  cursor: pointer;
}

.appSettings:hover {
  color: $accentHoverColor;
  transition: color 0.2s;
}

.emptyStateIllustration {
  width: 80px;
  height: 80px;
}

.tableHeaderText {
  font-size: $font-14;
  padding: 20px 15px;
  font-weight: normal;
  text-align: left;
  color: $secondaryColor;
}

.tableBodyText {
  font-size: $font-14;
  padding: 20px 30px;
  text-align: left;
  color: $primaryColor;
  border-bottom: 1px solid $backgroundColor;
}

.tableWrapper {
  max-height: 300px;
  overflow-y: auto;
}

.tableHeaderWrapper {
  width: 100%;
  background-color: $background-white-color;
}

.switch__container {
  margin: 7px auto;
  width: 35px;
  cursor: pointer;
}

.switch {
  visibility: hidden;
  position: absolute;
}

.switch + label {
  display: block;
  position: relative;
  cursor: pointer;
  outline: none;
  user-select: none;
}

.switch__shadow + label {
  padding: 2px;
  width: 35px;
  height: 20px;
  background-color: $white;
  border-radius: 18px;
}
.switch__shadow + label:before,
.switch__shadow + label:after {
  display: block;
  position: absolute;
  top: 1px;
  left: 1px;
  bottom: 1px;
  content: "";
}
.switch__shadow + label:before {
  right: 1px;
  background-color: rgba(144, 168, 190, 0.2);
  border-radius: 18px;
  transition: background 0.4s;
}

.switch__shadow + label:after {
  width: 18px;
  background-color: $white-color;
  border-radius: 100%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  transition: all 0.4s;
}

.switch__shadow:disabled + label {
  // background-color: $backgroundColor;
  background: #e0e0e0;
  box-shadow: inset 0px 2px 6px rgba(0, 0, 0, 0.15);
  border-radius: 50px;
}

.switch__shadow:disabled + label:after,
.switch__shadow:disabled + label:before {
  box-shadow: none;
}

.switch__shadow:checked + label:before {
  border: 0;
  // background-color: $success;
  background: #c9deff;
  box-shadow: inset 0px 2px 6px rgba(0, 0, 0, 0.15);

  position: absolute;
  top: -7%;
  line-height: 1;
  transition: inherit;
  box-sizing: content-box;
  bottom: -7%;
  width: 36px;
}

.switch__shadow:checked + label:after {
  transform: translateX(15px);
}

.switch_text {
  font-family: $outfit;
  font-style: normal;
  font-weight: 500;
  font-size: $d-font-12;
  line-height: 15px;
  text-align: center;

  color: $black-color;
}

.backText {
  font-size: $font-14;
  color: $secondaryColor;
}

.emptyStateText {
  font-size: $font-12;
  text-align: center;
  color: $secondaryColor;
}

.emptyStateDiv {
  height: 80%;
  text-align: center;
}

.tableRow:hover {
  .showWhiteDeleteIcon {
    display: none;
    transition: fill 0.2s;
  }
  .showRedDeleteIcon {
    display: block;
    transition: fill 0.2s;
  }
}

.showWhiteDeleteIcon {
  display: block;
}
.showRedDeleteIcon {
  display: none;
}
.whiteDeleteIcon {
  cursor: pointer;
  fill: $white;
}

.redDeleteIcon {
  cursor: pointer;
  fill: $error;
}

.btn {
  width: 150px;
  height: 40px;
  text-align: center;
  padding: 0 15px;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.16);
  cursor: no-drop;
  opacity: 0.5;
}

.btnText {
  font-size: $font-14;
  font-weight: 500;
  text-align: center;
  color: $white;
}

.primaryLink {
  color: $accentColor;
  font-size: 14px;
  font-weight: 500;
  border: none;
  outline: none;
  box-shadow: none;
  &:hover {
    border: none;
    outline: none;
    cursor: pointer;
    text-decoration: underline;
  }
}

.headerContainer {
  padding: 0px 30px !important;
}

.headerTitle {
  padding-left: 13px;
}

.borderRightManageServics {
  border-right: 1px solid $lite-accent-color;
}

.manageProductBtn {
  font-weight: 600;
  font-size: 10px !important;
  line-height: 13px;
}

.deleteColumn {
  width: 150px;
}

.noProductContainer {
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
}
