@import "../../../../../assets/scss/variables.scss";

* {
  font-family: "Poppins" sans-serif;
}

.content {
  height: 790px;
  width: 800px;
}

.trialText {
  font-size: 13px;
  line-height: 21px;
  font-weight: 400;
  color: #0075f3;
}

.headingText {
  font-size: 20px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
}

.infoForm {
  & > p {
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: #858585;
  }

  .label {
    font-size: 12px;
    font-weight: 600;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
  }
}

.inputContent{
    width: 30%;
}