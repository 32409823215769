.response_container {
    padding: 48px;
    gap: 40px;
    border-radius: 16px ;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 20px 0px #00000014;

    width: 50%;
    height: fit-content;
}

.txn_title_text {
    font-family: Outfit;
    font-size: 28px;
    font-weight: 600;
    line-height: 50.4px;
    text-align: center;
}

.success {
    color: #0F9D58
}

.error  {
    color: #DB4437
}

.processing {
    color: #FFC922
}


.txn_success_sub {
    font-family: Outfit;
    font-size: 18px;
    font-weight: 400;
    line-height: 33.6px;
    text-align: center;
}

.txn_id {
    font-family: Outfit;
    font-size: 14px;
    font-weight: 400;
    line-height: 22.4px;
    text-align: center;
    color: #969696;
}

.go_back {
    font-family: Outfit;
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    border: 0;
    background-color: transparent;
    color: #0075F3;
}

.processing_sub {
    font-size: 16px;
    font-weight: 400;
    line-height: 33.6px;
    text-align: center;
    color: #969696;
}