@import "../../../../assets/scss/variables.scss";

.headingText {
  font-size: $font-16;
  text-align: center;
  color: $primaryColor;
  font-weight: 500;
}

.subtext {
  font-size: $font-14;
  text-align: center;
  color: $error;
}

.key {
  font-size: $font-22;
  text-align: center;
  color: $accentColor;
  font-weight: 500;
  font-family: $firaSans;
}

.closeText {
  font-size: $font-12;
  text-align: center;
  color: $secondaryColor;
  cursor: pointer;
}

.closeText:hover {
  color: $error;
  transition: color 0.2s;
}
