@import "../../../assets/scss/variables.scss";

.authActionsBackground {
  background: $backgroundColor;
  height: 100%;
}

.formWidth {
  width: 300px;
}

.headingText {
  font-family: $firaSans;
  font-size: $font-24;
  font-weight: 500;
  color: $primaryColor;
}

.helperText {
  font-size: $font-12;
  text-align: left;
  color: $secondaryColor;
}

.passwordInstructions {
  font-size: $font-14;
  text-align: left;
  margin-bottom: 0;
}

.circle {
  width: 5px;
  height: 5px;
  border-radius: 50%;
}

.eye {
  object-fit: contain;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 54%;
}

@media screen and (max-width: 1200px) {
  .authActionsBackground {
    height: 100vh;
  }
}
