@import "../../../assets/scss/variables.scss";

.overlay {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.3);
  transition: background-color 0.3s;

  .popUpContent {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    width: 500px;
    height: 350px;
    border-radius: 10px;
    box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.16);
    background-color: $white;
    animation: fade 0.5s;
  }
}
.headingText {
  font-size: $font-16;
  font-weight: 500;
  text-align: center;
  color: $primaryColor;
}

@keyframes fade {
  from {
    top: -200%;
  }

  to {
    top: 50%;
  }
}
