@import "../../assets/scss/variables.scss";

* {
  font-family: "poppins", sans-serif;
}

.headingText {
  font-size: $font-18;
  font-weight: 600;
  text-align: center;
  color: $primaryColor;
}
