@import "../../assets/scss/variables.scss";

.sideBarBackground {
  height: 100vh ;
  width: 15vw;
  padding: 20px 0 0;
  background: #F7F7F7;
  box-shadow: -2px 0 5px 0 rgba(117, 59, 185, 0.1);
  position: fixed;
}

.sidebarCollapsed {
  height: 100vh;
    width: 5vw;
    padding: 20px 0 0;
    background: #F7F7F7;
    box-shadow: -2px 0 5px 0 rgba(117, 59, 185, 0.1);
    position: fixed;
}

.activeStateFill {
  fill: #0075F3;
}

.nonActiveStateFill {
  fill: $secondaryColor;
}

.activeState {
  height: 60px;
  color: white;
  position: relative;
  cursor: pointer;
  border-right: 6px solid transparent;
  border-image: linear-gradient(87.01deg, #22A8C0 0%, #2D87EE 100%);
  border-image-slice: 1;
  ;
}

.indicator {
  width: 5px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.activeState:hover {
  text-decoration: none;
}

.nonActiveState {
  height: 60px;
  cursor: pointer;
}

.nonActiveState:hover {
  text-decoration: none;
  background: $linkHoverBackground;

  .nonActiveLink {
    color: $accentColor;
  }
  .nonActiveStateFill {
    fill: $accentColor;
  }
}

.link {
  text-decoration: none;
}

.link:hover {
  text-decoration: none;
}

.activeLink {
  font-size: $font-14;
  font-weight: 600;
  text-align: left;
  color: #0075F3;;
}

.nonActiveLink {
  font-size: $font-14;
  font-weight: 500;
  text-align: left;
  color: $secondaryColor;
}

.footer {
  height: 10%;
  border-top: 1px solid $inputBackgroundColor;
  cursor: pointer;
}

.footer:hover {
  background: $linkHoverBackground;
  .nonActiveStateFill {
    fill: $accentColor;
  }
  .nonActiveLink {
    color: $accentColor;
  }
}

.rotate {
  transform: rotate(180deg);
  transition: transform 0.2s;
}

.showTooltip {
  display: none;
  position: absolute;
  left: 100%;
  z-index: 1;
  right: -10px;
  width: 100%;
  background: $primaryColor;
  border-radius: 4px;
  min-width: 150%;
  padding: 5px;
  margin: 0 2px;
}

.tooltipText {
  font-size: $font-14;
  font-weight: 600;
  color: $white;
  text-align: center;
}

.collapsedHover:hover {
  .showTooltip {
    display: block;
  }
}