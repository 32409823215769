@import "../../../../assets/scss/variables.scss";

.headingText {
  font-size: $d-font-18;
  font-weight: 600;
  text-align: center;
  color: $black-color;
}

.subText {
  text-align: center;

  font-family: $outfit;
  font-style: normal;
  font-weight: 400;
  font-size: $d-font-14;
  line-height: 18px;
  /* identical to box height */

  color: $text-secondary-color;
}

.spanText {
  font-size: $font-14;
  font-weight: 600;
  color: $primaryColor;
}
