@import "../../../../../assets/scss/variables.scss";

.cardHeader {
  box-shadow: $CardboxShadow;
  background-color: $cardHeaderColor;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.planCardBack {
  min-width: 210px;
  width: 100%;
  border-radius: 10px;
  box-shadow: $CardboxShadow;
  background-color: $white;
}

.planname {
  font-family: $firaSans;
  font-size: $font-14;
  font-weight: 500;
  text-align: left;
  color: $primaryColor;
}

.label {
  font-size: $font-12;
  text-align: left;
  color: $secondaryColor;
}

.value {
  font-size: $font-14;
  font-weight: 500;
  text-align: left;
  color: $primaryColor;
}

.disabled {
  font-size: $font-12;
  text-align: left;
  color: $secondaryColor;
}

.choosePlan {
  font-size: $font-12;
  font-weight: 500;
  text-align: left;
  color: $accentColor;
}

.choosePlan:hover {
  color: $accentHoverColor;
  transition: color 0.2s;
}

.rotateArrowRight {
  margin-top: 3px;
  transform: rotate(-90deg);
}

.currentPlanTabText {
  font-size: $font-12;
  text-align: center;
  color: $success;
}

.renewPlanText {
  font-size: $font-12;
  text-align: center;
  color: $error;
}

.renewPlanText:hover {
  color: $accentColor;
  transition: color 0.3s;
}

.pendingPaymentText {
  font-size: $font-12;
  text-align: center;
  color: $warning;
}
