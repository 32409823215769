.inputFieldPassport {
  height: 46px;
  width: 300px;
  left: 622px;
  top: 330px;
  border-radius: 8px;
  border: 1px solid #e8e8e8;
  outline: none;
  font-size: 14px;
  line-height: 21px;
}

.inputFieldPan {
  height: 46px;
  width: 100%;
  left: 622px;
  top: 330px;
  border-radius: 8px;
  border: 1px solid #e8e8e8;
  outline: none;
  font-size: 14px;
  line-height: 21px;
}

.inputFieldGst,
.inputFieldRc,
.inputFieldBankVerification,
.inputFieldDl,
.inputFieldVoter,
.inputFieldIfsc,
.inputFieldOkyc {
  height: 46px;
  width: 100%;
  left: 622px;
  top: 330px;
  border-radius: 8px;
  border: 1px solid #e8e8e8;
  outline: none;
  font-size: 14px;
  line-height: 21px;
}

.inputFieldOtp {
  height: 46px;
  width: 50%;
  left: 622px;
  top: 330px;
  border-radius: 8px;
  border: 1px solid #e8e8e8;
  outline: none;
  font-size: 14px;
  line-height: 21px;
}

.inputFieldUpperCase {
  text-transform: uppercase;
}
