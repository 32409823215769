@import "../../../../assets/scss/variables.scss";

.background {
  height: 100vh;
  background: $backgroundColor;
  color: $secondaryColor;
}
.card {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background: $background-white-color;
  box-shadow: $CardboxShadow;
  margin-top: 0;
}

.text_wrapper {
  width: 280px;
  margin: 0 auto;
}

.warning_image {
  width: 150px;
}

.onboarding_card {
  width: 100%;
  height: 85vh;
  border-radius: 8px;
  background: $background-white-color;
  position: sticky;
  top: 80px;
}

.cardAnimation {
  animation: $annimationName 0.6s ease;
}

.cardHeader {
  background-color: $background-white-color;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.onboarding_text {
  font-size: $font-14;
  font-weight: 500;
  text-align: center;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.95;
  color: $primary-color;
}

.onboarding_text_secondary {
  font-size: $font-14;
  font-weight: 500;
  text-align: center;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.95;
}

.headingText {
  font-size: $font-16;
  font-weight: 500;
  text-align: left;
  color: $primary-color;
}

.step_number_wrapper {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin: 0 auto;
}

.step_number {
  font-size: $font-16;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  text-align: left;
}

.success_step_text {
  font-size: $font-16;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  text-align: left;
  color: $success;
}

.current_step_text {
  font-size: $font-14;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  text-align: left;
  color: $accentColor;
}

.step_text {
  font-size: $font-14;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: normal;
  text-align: left;
  color: $secondaryColor;
}

.key {
  font-size: $font-12;
  text-align: left;
  color: $secondaryColor;
}

.value {
  font-size: $font-16;
  font-weight: 500;
  text-align: left;
  color: $primaryColor;
}

.progress_line {
  width: 4px;
  border-radius: 10px;
}

.activation_requested {
  font-size: $font-12;
  font-weight: 500;
  text-align: left;
  color: $success;
}
