@import "../../../../assets/scss/variables.scss";

progress {
  border-radius: 7px;
  height: 8px;
}

progress::-webkit-progress-bar {
  background-color: rgba(23, 181, 48, 0.1);
  border-radius: 7px;
}

progress::-webkit-progress-value {
  background-color: $successProgressColor;
  border-radius: 7px;
}

.progressDisable {
  progress::-webkit-progress-bar {
    background-color: #d9d9d9 !important;
    border-radius: 7px;
  }
  progress::-webkit-progress-value {
    background-color: #5e5e5e !important;
    border-radius: 7px;
  }
}

/* updated by @AmanMandloi */
.progressCardRow {
  height: 110px;
  overflow: auto;
  // border: none;

  // background: #f6f9fd;
  border-radius: 10px;
  height: 100px !important;

  td:last-child {
    border-right: 1px solid #dddcdd;
  }
}

.progressCardTable {
  border-collapse: separate;
  border-spacing: 0 20px;
  width: 100%;
  border: none;

  td {
    font-family: $outfit;
    font-style: normal;
    font-weight: 400;
    font-size: $d-font-14;
    overflow: auto;

    color: $black-color;
    border-top: 1px solid #dddcdd;
    border-bottom: 1px solid #dddcdd;
    padding-inline: 24px;
  }

  tr {
    // background: $background-white-color;
    border-radius: 10px;
    height: 200px !important;
  }
}

.batchIdText {
  color: #747474;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
}

.copyText {
  display: none;
}
.copyButton:hover {
  .copyText {
    display: block;
    position: absolute;
    margin-top: -48px;
    padding: 3px;
    font-size: 12px;
    background-color: black;
    color: white;
    border-radius: 5px;
  }
}

.downloadBtn {
  width: 129px !important;
  height: 32px !important;
  p {
    font-family: $poppins;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 21px;
    /* identical to box height */

    color: $white;

    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  svg {
    fill: $white;
  }
}

.disableBtn {
  svg {
    fill: $disableBtnTxt;
  }
  p {
    color: $disableBtnTxt !important;
  }
}

// .taskPushed {
//   progress::-webkit-progress-bar {
//     background-color: $backgroundColor !important;
//     border-radius: 7px;
//   }
//   progress::-webkit-progress-value {
//     background-color: $tableRowTextColor !important;
//     border-radius: 7px;
//   }
// }

.progressColumn {
  width: 30%;
}

.headerLeft {
  border-top-left-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

.headerRight {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.actionButton {
  height: 48px;
  width: 60px;
  // margin-left: 5%;
  // margin-right: 5%;
  // background: whitesmoke;
  border-radius: 5px;
  cursor: pointer;
}

.stopBtn {
  // background: #efcac924;
  // &:hover {
  //   background: #efcac97d;
  // }
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.progressAdjustment {
  // margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 250px;
}

.batchdetailsHeading {
  font-family: Outfit;
  font-size: 20px;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 30px;
}

.key {
  font-family: Outfit, sans-serif;
  font-weight: 600;
}

.batchIdValue {
  color: var(--Header-Text-Grey, #414243);
  // margin-top: 26px;
  font: 400 12px/22px Poppins, -apple-system, Roboto, Helvetica, sans-serif;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.value {
  color: var(--Header-Text-Grey, #414243);
  // margin-top: 26px;
  font: 400 12px/22px Poppins, -apple-system, Roboto, Helvetica, sans-serif;
}

.batchName {
  color: #000;
  font: 500 16px/24px Poppins, -apple-system, Roboto, Helvetica, sans-serif;
  margin-bottom: 0.4rem;
  font-weight: 600;
}

.borderGap {
  height: 120px;
  border-left: 1px solid lightgray;
  margin-top: 15px;
}
.borderGap2 {
  height: 120px;
  border-left: 1px solid lightgray;
  margin-top: 15px;
}
.waitingForApproval {
  color: #969696;
  white-space: nowrap;
  justify-content: center;
  border-radius: 8px;
  background-color: #f7f7f7;
  align-self: center;
  width: fit-content;
  margin: auto 0;
  margin-left: 80px;
  padding: 4px 10px;
  font: 600 12px Outfit, sans-serif;
}
@media (max-width: 995px) {
  .waitingForApproval {
    white-space: nowrap;
    margin: auto;
  }
  .borderGap2 {
    margin-left: auto;
    margin-right: auto;
  }
}

.errorBox {
  color: var(--Red, #db4437);
  justify-content: center;
  border-radius: 8px;
  width: fit-content;
  background-color: rgba(219, 68, 55, 0.2);
  margin-top: 22px;
  padding: 4px 8px;
  font: 600 12px Poppins, sans-serif;
}

.finishedBox {
  color: var(--Green, #0f9d58);
  justify-content: center;
  border-radius: 8px;
  width: fit-content;
  background-color: rgba(15, 157, 88, 0.2);
  margin-top: 22px;
  padding: 4px 8px;
  font: 600 12px Poppins, sans-serif;
}
.progressBox {
  color: var(--Purple, #7f00ff);
  justify-content: center;
  border-radius: 8px;
  width: fit-content;
  background-color: rgba(158, 176, 246,0.5);
  margin-top: 22px;
  padding: 4px 8px;
  font: 600 12px Poppins, sans-serif;
}

.pausedBox {
  color: var(--Yellow, #ffc922);
  justify-content: center;
  border-radius: 8px;
  width: fit-content;
  background-color: rgba(255, 201, 34, 0.2);
  margin-top: 22px;
  padding: 4px 8px;
  font: 600 12px Poppins, sans-serif;
}
.progressHead {
  font-family: Outfit;
  font-size: 14px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0px;
  text-align: left;
}
