@import "../../../assets/scss/variables.scss";

.authActionsBackground {
  background: $white;
  height: 100%;
}

.text {
  font-size: $font-12;
  text-align: center;
  color: $secondaryColor;
}
.text2 {
  font-size: $font-12;
  text-align: center;
  color: $accentColor;
}

.signUpText {
  font-size: $font-14;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  color: $accentColor;
}

.signUpText:hover {
  text-decoration: none;
  color: $accentHoverColor;
  transition: color 0.2s;
}

.formWidth {
  width: 300px;
}

.visitBtn {
  border: none;
  outline: 0 !important;
  font-size: 14px;
  cursor: pointer;
  color: $secondaryColor;
  background-color: $white;

  &:hover {
    color: $accentColor;
  }
}

.redirectText {
  color: $error;
  text-decoration: underline;
}
.redirectText:hover {
  color: $accentColor;
}
@media screen and (max-width: 1200px) {
  .authActionsBackground {
    height: 100vh;
  }
}
