@import "../../../../assets/scss/variables.scss";

.requirementText {
  text-align: left;
  font-family: $outfit;
  font-style: normal;
  font-weight: 700;
  font-size: $d-font-14;
  line-height: 18px;
  /* identical to box height */

  color: $black-color;
}
