@import "../../../assets/scss/variables.scss";

.brandText {
  font-family: $firaSans;
  font-size: $font-24;
  font-weight: 500;
  text-align: center;
  color: $white;
}

.bodyText {
  font-size: $font-14;
  text-align: center;
  color: $secondaryColor;
}
