@import "../../../assets/scss/variables.scss";

.billingCard {
  width: 100%;
  border-radius: 10px;
  box-shadow: $CardboxShadow;
  background-color: $white;
}

.cardAnimation {
  animation: $annimationName 0.5s ease;
}

.cardHeader {
  background-color: $background-white-color;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.overallheadingText {
  font-family: $firaSans;
  font-size: $font-16;
  font-weight: 500;
  text-align: left;
  color: $primaryColor;
}

.headingText {
  font-family: $firaSans;
  font-size: $font-14;
  font-weight: 500;
  text-align: left;
  color: $primaryColor;
}

.changePlanText {
  font-size: $font-12;
  font-weight: 500;
  color: $accentColor;
  cursor: pointer;
}

.changePlanText:hover {
  color: $accentHoverColor;
  transition: color 0.2s;
  text-decoration: none;
}

.rotateArrowRight {
  margin-top: 3px;
  transform: rotate(-90deg);
}

.key {
  text-align: left;
  font-family: $outfit;
  font-style: normal;
  font-weight: 400;
  font-size: $d-font-12;
  line-height: 15px;

  color: $text-secondary-color;
}

.value {
  font-family: $outfit;
  font-style: normal;
  font-weight: 400;
  font-size: $d-font-16;
  line-height: 23px;
  /* identical to box height */

  text-align: left;

  color: $black-color;
}

.tableHeaderWrapper {
  width: 100%;
  background-color: $background-white-color;
}

.planHistoryOverlay {
  max-height: 330px;
  height: 100%;
  overflow-y: auto;
}

.tableHeader {
  font-size: $font-14;
  padding: 15px;
  font-weight: 500;
  text-align: left;
  color: $secondaryColor;
}

.tableBody {
  font-size: $font-14;
  padding: 20px 15px;
  text-align: left;
  color: $primaryColor;
  border-bottom: 1px solid $border-color;
}

.downloadLink {
  font-size: $font-14;
  font-weight: 500;
  text-align: left;
  color: $accentColor;
  border-bottom: 1px solid $backgroundColor;
  cursor: pointer;
}

.downloadLink:hover {
  color: $accentHoverColor;
  transition: color 0.2s;
}

td,
th {
  border: 0;
  text-align: left;
}

.pendingStatus {
  font-size: $font-12;
  text-align: right;
  color: $warning;
  cursor: no-drop;
}

.emptyStateText {
  font-size: $font-12;
  text-align: center;
  color: $secondaryColor;
}

.noServiceIllustration {
  width: 100px;
  height: 100px;
}

.emptyStateDiv {
  height: 310px;
  text-align: center;
}

.cancelText {
  font-size: $font-12;
  color: $error;
  cursor: pointer;
  text-decoration: underline;
}
.editTextStyle {
  font-size: $font-12;
  color: $primary-color;
  cursor: pointer;
}

.editTextStyle:hover {
  color: $accentHoverColor;
  text-decoration: none;
  transition: color 0.2s;
}

.currentPlan {
  font-size: $font-16;
  color: #000000;
  cursor: pointer;
}

.currentPlan:hover {
  color: $primary-color;
  text-decoration: none;
  transition: color 0.2s;
}
thead,
tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.tableWrapper {
  display: block;
  min-height: 250px;
  max-height: 250px;
  overflow: auto;
}

@media screen and (max-width: 767px) {
  .changePlanText {
    font-size: $font-14;
  }

  .key {
    font-size: $font-12;
  }

  .value {
    font-size: $font-14;
  }
}

.bodyClassName {
  max-height: 40vh;
  min-height: 40vh;
  display: block;
  overflow: auto;
}
