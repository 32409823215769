@import "../../../assets/scss/variables.scss";

.formBG {
  background: $white;
  padding: 20px;
  border-radius: 10px;
  border: 0.5px solid #dfdfdf;
  position: relative;
  top: -10px;
  margin: 0 10px;
}
