@import "../../../../../assets//scss//variables.scss";

.tryNowButton {
  background: linear-gradient(87.01deg, #22a8c0 0%, #2d87ee 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  width: 150px;
  height: 40px;
  padding: 5px;
  font-size: $font-14;
  font-weight: 500;
  outline: none;
  border: none;
  border-radius: 8px;

  &:hover {
    text-decoration: none;
    color: white;
  }
}
