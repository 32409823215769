@import "../assets/scss/variables.scss";

.overlay {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 99;
  background-color: rgba(38, 50, 56, 0.7);
  transition: background-color 0.3s;
  animation: fade 0.3s ease-in-out;

  .popUpContent {
    min-width: 400px;
    border-radius: 10px;
    box-shadow: 0 0 25px 0 rgba(122, 106, 158, 0.25);
    background-color: $white;
  }
}

.warningMessage {
  font-size: $font-14;
  font-weight: 500;
  color: $primaryColor;
}

.eye {
  object-fit: contain;
  cursor: pointer;
  position: absolute;
  right: 10px;
}

@keyframes fade {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.customAlertClass {
  border-radius: 10px !important;
}
