@import "../../../../assets/scss/variables.scss";

.loadingCard {
  height: 255px;
  background: $white;
  box-shadow: $CardboxShadow;
  border-radius: 8px;
}

.card {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background: $white;
  box-shadow: CardboxShadow;
  margin-top: 0;

  border: 1px solid #dddddd;
  border-radius: 8px;
}

.headingText {
  font-family: $firaSans;
  font-size: $font-14;
  font-weight: 500;
  width: 80%;
  text-align: left;
  color: $primaryColor;
  cursor: default;
}

.cardAnimation {
  animation: $annimationName 0.5s ease;
}

.serviceDescription {
  font-family: $outfit;
  font-style: normal;
  font-weight: 400;
  font-size: $d-font-14;
  line-height: 18px;
  /* identical to box height */

  color: #696969;
}

.labelText {
  font-size: $font-12;
  color: $primaryColor;
}

.SVGcolor {
  fill: $accentColor;
  height: 15px;
  width: 15px;
}

.unsubscribeService {
  font-size: $font-12;
  text-align: left;
  color: $secondaryColor;
  text-decoration: underline;
  cursor: pointer;
}

.unsubscribeService:hover {
  color: $error;
  transition: color 0.3s;
}

.trialTransactionText {
  text-align: left;
  white-space: nowrap;

  font-family: $outfit;
  font-style: normal;
  font-weight: 600;
  font-size: $d-font-14;
  line-height: 18px;
  /* identical to box height */

  color: $black-color;
}

.transactionCount {
  font-size: $font-14;
  text-align: left;
  color: $primaryColor;
}

.approvalRequired {
  font-size: $font-12;
  text-align: left;
  color: $secondaryColor;
  white-space: nowrap;
}

.subscribedStatus {
  font-size: $font-12;
  font-weight: 500;
  text-align: right;
  color: $success;
  cursor: no-drop;
}

.pendingStatus {
  text-align: right;
  cursor: no-drop;

  font-family: $outfit;
  font-style: normal;
  font-weight: 400;
  font-size: $d-font-14;
  line-height: 18px;
  /* identical to box height */

  color: $strong-orange-color;
}

.buttonText {
  font-size: $font-12;
  color: $white;
}

.subscribeServiceBackground {
  width: 110px;
  margin: 0 auto;
  border-radius: 8px;
  height: 30px;
  background: $success;
  cursor: pointer;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.16);
}
.subscribeServiceBackground:hover {
  background: $success;
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.badgeWrapper {
  border: 1px solid #dddddd;
  border-radius: 36px;
}

.subscribeBtn {
  background: $lite-green-color !important;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}
