@import "../../assets/scss/variables.scss";

.switch__container {
  margin: 7px auto;
  width: 35px;
  cursor: pointer;
}

.switch {
  visibility: hidden;
  position: absolute;
}

.switch + label {
  display: block;
  position: relative;
  cursor: pointer;
  outline: none;
  user-select: none;
}

.switch__shadow + label {
  padding: 2px;
  width: 35px;
  height: 20px;
  background-color: $white;
  border-radius: 18px;
}
.switch__shadow + label:before,
.switch__shadow + label:after {
  display: block;
  position: absolute;
  top: 1px;
  left: 1px;
  bottom: 1px;
  content: "";
}
.switch__shadow + label:before {
  right: 1px;
  background-color: rgba(144, 168, 190, 0.2);
  border-radius: 18px;
  transition: background 0.4s;
}
.switch__shadow + label:after {
  width: 18px;
  background: $primary-color;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
  border-radius: 100%;
  transition: all 0.4s;

  background: #0f9d58;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.25);
}

.switch__shadow:disabled + label {
  background-color: $backgroundColor;
}

.switch__shadow:disabled + label:after,
.switch__shadow:disabled + label:before {
  box-shadow: none;
}

.switch__shadow:checked + label:before {
  border: 0;
  // background: $lite-accent-color;
  // box-shadow: inset 0px 2px 6px rgba(0, 0, 0, 0.15);
  border-radius: 50px;

  background: #d3ffd5;
  box-shadow: inset 0px 2px 6px rgba(0, 0, 0, 0.15);

  position: absolute;
  top: -7%;
  line-height: 1;
  transition: inherit;
  box-sizing: content-box;
  bottom: -7%;
  width: 40px;
  left: -3px;
}

.switch__shadow:not(:checked) + label:before {
  background: $devDocsGray;
  box-shadow: inset 0px 2px 6px rgba(0, 0, 0, 0.15);
  border-radius: 50px;

  position: absolute;
  top: -7%;
  line-height: 1;
  transition: inherit;
  box-sizing: content-box;
  bottom: -7%;
  width: 40px;
  left: -3px;
}

.switch__shadow:not(:checked) + label:after {
  background: $white-color;
}

.switch__shadow:checked + label:after {
  transform: translateX(15px);
}

.switch_text {
  font-family: $outfit;
  font-style: normal;
  font-weight: 400;
  font-size: $d-font-14;
  line-height: 18px;
  /* identical to box height */
  text-align: center;

  color: $black-color;
}

.switch_text_special{
  font-family: $outfit;
  font-style: normal;
  font-weight: 400;
  font-size: $d-font-14;
  line-height: 18px;
  /* identical to box height */
  text-align: center;
  color: $devDocsGreen;
}

.switch__shadow:disabled + label:before {
  background: rgba($accentColor, 0.4);
  cursor: no-drop;
}

.switch__shadow:disabled + label:after {
  cursor: no-drop;
}
