@import "../../assets/scss/variables.scss";

.arrow_svg_fill {
  fill: $primary-color;
}
.rotateLeft {
  cursor: pointer;
  transform: rotate(+180deg);
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin: 0 5px;
}

.rotateRight {
  cursor: pointer;
  // transform: rotate(-180deg);
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin: 0 5px;
}
.rotateRight:hover,
.rotateLeft:hover {
  border: 1px solid $secondaryColor;
}

.pageNumberText {
  font-family: $outfit;
  font-style: normal;
  font-weight: 400;
  font-size: $d-font-14;
  line-height: 18px;
  display: flex;
  align-items: center;

  color: $text-secondary-color;
}

.pageNumberInActive {
  // width: 30px;
  // height: 30px;
  // border-radius: 8px;
  // border: solid 1px $secondaryColor;
  // background-color: $white;

  cursor: pointer;
  width: 25px;
  height: 25px;

  background: $white-color;
  border: 0.5px solid $text-secondary-color;
  border-radius: 4px;
}

.pageNumberActive,
.pageNumberInActive:hover {
  width: 30px;
  height: 30px;
  border: 0;
  cursor: pointer;
  border-radius: 4px;
  background-color: $primary-color;
  transition: background-color 0.5s;

  .pageNumberText {
    color: $white;
    transition: color 0.2s;
  }
}
