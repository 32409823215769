@import "../../../../assets/scss/variables.scss";

* {
  font-family: "Poppins" sans-serif;
}

.content {
  width: 600px;
  padding: 20px;
}

.trialText {
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  color: #0075f3;
}

.headingText {
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: 0em;
  text-align: left;
}

.ackText {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}

.infoForm {
  & > p {
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    color: #858585;
  }

  .label {
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
  }

  .labelLite {
    font-size: 14px;
    color: red;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
  }

  .buttonGroup {
    display: flex;
    width: 62%;
    justify-content: space-between;

    .submitButton {
      background-color: #0075f3;
      width: 192px;
      height: 45px;
      left: calc(50% - 158px / 2 - 642px);
      top: calc(50% - 55px / 2 + 344px);
      border-radius: 8px;
      border: none;
      outline: none;
      opacity: 0.8;
      color: #ffffff;
      padding: 5px 0px;
    }

    .disabledSubmitButton {
      background-color: gray;
      width: 192px;
      height: 45px;
      left: calc(50% - 158px / 2 - 642px);
      top: calc(50% - 55px / 2 + 344px);
      border-radius: 8px;
      border: none;
      outline: none;
      opacity: 0.8;
      color: #ffffff;
      padding: 5px 0px;
      cursor: not-allowed;
    }

    .cancelButton {
      background-color: #e8e8e8;
      width: 138px;
      height: 45px;
      left: calc(50% - 158px / 2 - 642px);
      top: calc(50% - 55px / 2 + 344px);
      border-radius: 8px;
      border: none;
      outline: none;
      opacity: 0.8;
      color: #636363;
    }
  }
}

.inputContainer {
  width: 300px;
  margin-right: 5%;
}

.buttonContainer {
  width: 130px;
  height: 44px;
}

