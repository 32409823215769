@import "../../assets/scss/variables.scss";

.dropdown {
  background: $inputBackgroundColor;
  border-radius: 2px;
  padding: 10px 15px;
  width: 100%;
  text-align: left;
  cursor: pointer;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.16);
  transition: box-shadow 0.3s ease-in-out;
  border: 1px solid #e8e8e8;
}

.dropdown:focus {
  border: 1px solid $primary-color;
  border-radius: 2px;
}

.indicator {
  position: absolute;
  top: 15%;
  right: 5%;
}
.validDropdown {
  background: $inputBackgroundColor;
  border-radius: 2px;
  padding: 10px 15px;
  width: 100%;
  text-align: left;
  cursor: pointer;
  box-shadow: 0 3px 10px 0 rgba(0, 117, 243, 0.16);
  transition: box-shadow 0.3s ease-in-out;
  border: 1px solid #e8e8e8;
}

.selectedText {
  font-size: $font-13;
  text-align: left;
  color: $primaryColor;
  white-space: nowrap;
  width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dropdownError {
  background: $inputBackgroundColor;
  border-radius: 2px;
  padding: 10px 15px;
  width: 100%;
  text-align: left;
  cursor: pointer;
  box-shadow: 0 3px 10px 0 rgba(214, 71, 71, 0.16);
  transition: box-shadow 0.3s ease-in-out;
  border: 1px solid #e8e8e8;
}
.dropdownMenu {
  padding: 10px;
  width: 100%;
  max-height: 250px;
  overflow-y: auto;
  -ms-overflow-style: none;
  background: $white;
  border-radius: 8px;
  border: 0;
  scrollbar-width: none;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.16);
  transition: box-shadow 0.3s ease-in-out;
}

.dropdownLink {
  background: $white;
  font-size: $font-13;
  color: $primaryColor;
  display: block;
  padding: 10px 15px;
  text-align: left;
  border: 0;
  cursor: pointer;
}

.dropdownLink:hover {
  color: $accentColor;
  font-weight: 500;
  text-decoration: none;
  background: $linkHoverBackground;
  border-radius: 8px;
}

.active {
  color: $accentColor;
  display: block;
  width: 100%;
  padding: 10px 15px;
  font-weight: 600;
  cursor: pointer;
  text-align: left;
  // white-space: nowrap;
  border: 0;
  font-size: $font-14;
  border-radius: 8px;
}

.formLabel {
  font-family: $outfit;
  font-style: normal;
  font-weight: 500;
  font-size: $d-font-12;
  line-height: 15px;
  color: $black-color;
}

.mandatoryField {
  color: $danger;
}

/* width */
.dropdownMenu::-webkit-scrollbar {
  width: 8px;
}

/* Track */
.dropdownMenu::-webkit-scrollbar-track {
  background: $backgroundColor;
  border-radius: 10px;
  position: absolute;
  right: 0;
}

/* Handle */
.dropdownMenu::-webkit-scrollbar-thumb {
  background: #dfdfdf;
  border-radius: 10px;
}

.dropdownMenu::-webkit-scrollbar-track-piece {
  background: $backgroundColor;
  border-radius: 10px;
}

.noShadow {
  box-shadow: none;
}

.placeholder {
  font-size: $d-font-14;
}
