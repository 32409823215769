@import "../../assets/scss/variables.scss";

* {
  font-family: "poppins", sans-serif;
}

.headingText {
  font-size: $font-18;
  font-weight: 600;
  text-align: center;
  color: $primaryColor;
}

.subText {
  font-size: $font-14;
  text-align: center;
  line-height: 21px;
  color: $secondaryColor;
}

.cancelText {
  font-size: $font-12;
  color: $secondaryColor;
  cursor: pointer;
  margin-top: 15px;
}

.cancelText:hover {
  color: $error;
  transition: color 0.3s ease-in;
  text-decoration: underline;
  margin-top: 15px;
}

.resendOtpText {
  font-size: $font-12;
  color: #005fc6;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
}

.resendOtpText:hover {
  color: $accentColor;
  transition: color 0.3s ease-in;
}

.commonInputClass {
  display: block;
  width: 100%;
  padding: 10px 15px;
  font-size: $font-14;
  font-weight: 400;
  border-radius: 4px;
  line-height: 1.5;
  color: $primaryColor;
  background: $inputBackgroundColor;
}

.formControl {
  border: 1px solid lightgray;
}

.error {
  //
}

.valid {
  //
}

.formControl::-moz-placeholder {
  font-size: $font-12;
  text-align: left;
  color: $secondaryColor;
}

.formControl::placeholder {
  font-size: $font-16;
  text-align: center;
  color: $secondaryColor;
}

.error::-moz-placeholder {
  font-size: $font-12;
  text-align: left;
  color: $secondaryColor;
}

.error::placeholder {
  font-size: $font-12;
  text-align: left;
  color: $secondaryColor;
}

.formControl:focus {
  outline: none;
}

.valid:focus {
  outline: none;
}

.error:focus {
  outline: none;
}

.formControl:disabled {
  opacity: 0.5;
}

.overlay {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.3);
  transition: background-color 0.3s;
  animation: fade 0.3s ease-in-out;

  .popUpContent {
    width: 400px;
    border-radius: 10px;
    box-shadow: 0 0 25px 0 rgba(122, 106, 158, 0.25);
    background-color: $white;
  }
}

.warningMessage {
  font-size: $font-14;
  font-weight: 500;
  color: $primaryColor;
}

.eye {
  object-fit: contain;
  cursor: pointer;
  position: absolute;
  right: 10px;
}

@keyframes fade {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.wrongOtp {
  border: 1px solid $error;
}
