@import "../../../../assets/scss/variables.scss";

.serviceCardWrapper {
  padding: 20px;
  width: 100%;
  cursor: pointer;
}
.serviceCardWrapper:hover {
  background: rgba(0, 0, 0, 0.02);
}
.serviceName {
  font-family: $firaSans;
  font-size: $font-16;
  font-weight: 500;
  text-align: left;
  color: $primaryColor;
}

.DescText {
  font-size: $font-13;
  text-align: left;
  color: $secondaryColor;
}

.limit {
  font-size: $font-12;
  text-align: right;
  white-space: nowrap;
  color: $secondaryColor;
}

.limitValue {
  position: "relative";
  font-size: $font-14;
  font-weight: 600;
  text-align: left;
  color: $primaryColor;
}

.unVisited {
  width: 20px;
  height: 20px;
  border: 1px solid $primaryColor;
  opacity: 0.5;
  border-radius: 4px;
}

.stepCompleted {
  width: 20px;
  height: 20px;
  background: $accentColor;
  border-radius: 4px;
  position: relative;
}
