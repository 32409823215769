@import "../../assets/scss/variables.scss";

.circle_bg {
  display: block;
  transform: rotate(-90deg);
}
.stepNumber {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  font-size: $font-18;
}
