.chip {
    width: fit-content;
    padding: 4px 8px 4px 8px;
    border-radius: 8px ;
    font-size: 12px;
    font-weight: 500;
    line-height: 15.12px;
    text-align: left;
}

.success {
    background: #C8E7DC;
    color: #0F9D58;
}

.error {
    background: #F1D5D5;
    color: #DB4437;
}

.processing {
    background: #F7F2DC;
    color: #FFC922;
}
