@import "../../../assets/scss/variables.scss";

.card {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background: $background-white-color;
  box-shadow: $CardboxShadow;
  margin-top: 0;
}

.cardHeader {
  background-color: $background-white-color;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.headingText {
  font-family: $firaSans;
  font-size: $font-14;
  font-weight: 500;
  text-align: left;
  color: $primaryColor;
}

.key {
  font-size: $font-12;
  font-weight: 500;
  text-align: left;
  color: $secondaryColor;
}

.value {
  font-size: $font-14;
  text-align: left;
  color: $primaryColor;
}

.keyWidth {
  flex-basis: 30%;
}

.valueWidth {
  flex-basis: 70%;
}

.card1Animation {
  animation: $annimationName 0.5s ease;
}

.card2Animation {
  animation: $annimationName 0.5s ease;
}

.card3Animation {
  animation: $annimationName 0.6s ease;
}

.card4Animation {
  animation: $annimationName 0.6s ease;
}

.editTextStyle {
  font-size: $font-12;
  color: $accentColor;
  cursor: pointer;
}

.editTextStyle:hover {
  color: $accentHoverColor;
  text-decoration: none;
  transition: color 0.2s;
}

.background {
  min-height: 100vh;
  height: 100%;
  background: $white;
}

.rotate {
  transform: rotate(-90deg);
}

.backText {
  font-size: $font-14;
  color: $secondaryColor;
}

.loadingCard {
  width: 100%;
  height: 260px;
  background: $white;
  border-radius: 8px;
  box-shadow: $CardboxShadow;
}

@media screen and (max-width: 676px) {
  .key {
    font-size: $font-14;
  }

  .value {
    font-size: $font-14;
  }
}
