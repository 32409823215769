@import "../../../assets/scss/variables.scss";

.background {
  height: 100vh;
  background: $backgroundColor;
}

.resendEmailLink {
  border: 0;
  background: none;
  color: $accentColor;
  padding: 0 5px;
  font-size: $font-16;
  font-weight: 500;
}

.resendEmailLink:hover {
  color: $accentHoverColor;
  text-decoration: underline;
}

.signUpLink {
  font-size: $font-14;
  font-weight: 500;
  text-align: center;
  color: $accentColor;
}

.signUpLink:hover {
  color: $accentHoverColor;
  text-decoration: none;
}
