@import "../../../assets/scss/variables.scss";

.authActionsBackground {
  background: $white;
  height: 100%;
}

.formWidth {
  width: 300px;
}

.headingText {
  font-family: $firaSans;
  font-size: $font-24;
  font-weight: 600;
  color: $primaryColor;
}

.formLabel {
  font-size: $font-14;
  font-weight: 400;
  text-align: left;
  color: $primaryColor;
}

.formControl {
  background: $inputBackgroundColor;
  padding: 10px 15px;
  color: $primaryColor;
  position: sticky;
  top: 0;
  width: 100%;
  font-size: $font-14;
  font-weight: 400;
  border-radius: 4px;
  line-height: 1.5;
  color: $primaryColor;
}

.formControl:focus {
  outline: none;
  box-shadow: none;
}

.formControl::-moz-placeholder {
  font-size: $font-12;
  text-align: left;
  color: $secondaryColor;
}

.formControl::placeholder {
  font-size: $font-12;
  text-align: left;
  color: $secondaryColor;
}

.uncheckedCheckBox {
  width: 15px;
  height: 15px;
  border: 1px solid $primaryColor;
  border-radius: 4px;
}

.checkedCheckBox {
  width: 15px;
  height: 15px;
  background: $accentColor;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 1px;
}

.sameAsBusinessNameText {
  font-size: 12px;
  font-weight: 500;
  color: $secondaryColor;
  text-align: left;
}

@media screen and (max-width: 1200px) {
  .authActionsBackground {
    height: 100vh;
  }
}

.countrySelect {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  width: 25px !important;
  height: 20px !important;
}
