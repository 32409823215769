@import "../../../assets/scss/variables.scss";

* {
  font-family: "poppins", sans-serif;
}

.headingText {
  font-size: $font-18;
  font-weight: 600;
  text-align: center;
  color: $primaryColor;
}

.subText {
  font-size: $font-14;
  text-align: center;
  line-height: 21px;
  color: $secondaryColor;
}

.cancelText {
  font-size: $font-12;
  color: $secondaryColor;
  cursor: pointer;
}

.cancelText:hover {
  color: $error;
  transition: color 0.3s ease-in;
  text-decoration: underline;
}

.resendOtpText {
  font-size: $font-12;
  color: #005fc6;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
}

.resendOtpText:hover {
  color: $accentColor;
  transition: color 0.3s ease-in;
}

.commonInputClass {
  display: block;
  width: 100%;
  padding: 10px 15px;
  font-size: $font-14;
  font-weight: 400;
  border-radius: 4px;
  line-height: 1.5;
  color: $primaryColor;
  background: $inputBackgroundColor;
}

.formControl {
  border: 1px solid lightgray;
}

.formControl::-moz-placeholder {
  font-size: $font-12;
  text-align: left;
  color: $secondaryColor;
}

.formControl::placeholder {
  font-size: $font-16;
  text-align: center;
  color: $secondaryColor;
}

.error::-moz-placeholder {
  font-size: $font-12;
  text-align: left;
  color: $secondaryColor;
}

.error::placeholder {
  font-size: $font-12;
  text-align: left;
  color: $secondaryColor;
}

.formControl:focus {
  outline: none;
}

.valid:focus {
  outline: none;
}

.error:focus {
  outline: none;
}

.formControl:disabled {
  opacity: 0.5;
}

.wrongOtp {
  border: 1px solid $error;
}
