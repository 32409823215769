@import "../../../../assets/scss/variables.scss";

.overlay {
  width: 100%;
  height: auto;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 3;
  background-color: rgba(0, 0, 0, 0.3);
  transition: background-color 0.3s;
  animation: fade 0.3s ease-in-out;

  .popUpContent {
    border-radius: 10px;
    box-shadow: 0 0 25px 0 rgba(122, 106, 158, 0.25);
    background-color: $white;
  }
}

.cancelButton {
  background-color: white;
  border: none;
  outline: none;
  opacity: 0.8;
  font-size: 25px;
}
