@import "../../assets/scss/variables.scss";

.required:after {
  content: " *";
  color: $error;
  padding-left: 5px;
}

input {
  border: 0;
  font-size: $font-12;
}

.infoIcon {
  fill: $secondaryColor;
}

.mandatoryField {
  margin-left: 2px;;
  color: $danger;
}

.InfoIconBack {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: transparent;
  cursor: default;
}

.InfoIconBack:hover {
  background: $backgroundColor;
  .infoIcon {
    fill: $secondaryColor;
  }
}

.indicator {
  position: absolute;
  top: 18%;
  right: 5%;
}

input::placeholder {
  font-size: $d-font-14 !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
}