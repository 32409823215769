@import "../../../assets/scss/variables.scss";

.authActionsBackground {
  background: $white;
  height: 100%;
}

.welcomeMessage {
  font-family: $firaSans;
  font-size: 48px;
  font-weight: 400;
  color: $primaryColor;
}

.bodyText {
  font-size: $font-14;
  text-align: center;
  color: $secondaryColor;
}

.email {
  font-size: $font-16;
  font-weight: 500;
  color: $primaryColor;
  cursor: pointer;
}

.linkText {
  font-size: $font-14;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  color: $accentColor;
}

.linkText:hover {
  text-decoration: none;
  color: $accentHoverColor;
  transition: color 0.2s;
}

@media screen and (max-width: 1200px) {
  .authActionsBackground {
    height: 100vh;
  }
}
