@import "../../assets/scss/variables.scss";

.switch {
  visibility: hidden;
  position: absolute;
}

.switch + label {
  display: block;
  position: relative;
  cursor: pointer;
  outline: none;
  user-select: none;
}

.switch__shadow + label {
  padding: 2px;
  width: 35px;
  height: 20px;
  background-color: #b6b6b6;
  border-radius: 18px;
}
.switch__shadow + label:before,
.switch__shadow + label:after {
  display: block;
  position: absolute;
  top: 1px;
  left: 1px;
  bottom: 1px;
  content: "";
}
.switch__shadow + label:before {
  right: 1px;
  background-color: rgba(144, 168, 190, 0.2);
  border-radius: 18px;
  transition: background 0.4s;
}
.switch__shadow + label:after {
  width: 18px;
  background-color: $white;
  border-radius: 100%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  transition: all 0.4s;
}

.switch__shadow:disabled + label {
  background-color: $backgroundColor;
}

.switch__shadow:disabled + label:after,
.switch__shadow:disabled + label:before {
  box-shadow: none;
}

.switch__shadow:checked + label:before {
  border: 0;
  background-color: #0f9d58;
}
.switch__shadow:checked + label:after {
  transform: translateX(15px);
}

.switch__shadow:disabled + label:before {
  background: rgba($accentColor, 0.4);
  cursor: no-drop;
}

.switch__shadow:disabled + label:after {
  cursor: no-drop;
}
