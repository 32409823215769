@import "../../../assets/scss/variables.scss";

.headingText {
  font-family: $firaSans;
  font-size: $font-16;
  font-weight: 500;
  text-align: left;
  color: $primaryColor;
}

.inputWrapper {
  background-color: #dfdfdf;
  border-radius: 8px;
}

.formControl {
  display: block;
  width: 100%;
  padding: 10px 5px;
  font-size: $font-14;
  font-weight: 400;
  line-height: 1.5;
  color: $primaryColor;
  background: transparent;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.formControl::placeholder {
  font-size: $font-12;
  color: $secondaryColor;
}

.formControl:focus {
  outline: none;
  box-shadow: none;
}

.emptyState {
  height: calc(100vh - 250px);
  width: 100%;
  overflow: auto;
}
.message {
  font-size: $font-16;
  font-family: $firaSans;
  font-weight: 500;
  text-align: center;
  color: $primaryColor;
}

.filterTabContainer {
  border-bottom: 1px solid #e1e1e1;
  width: 80%;
  overflow-x: auto;
}

.dropdownContainer {
  width: 20%;
  display: flex;
  justify-content: flex-end;
  padding-right: 2em;
}
