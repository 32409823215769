@import "../../../assets/scss/variables.scss";

.authActionsBackground {
  background: $white;
  height: 100%;
}

.formWidth {
  width: 300px;
}

.text {
  font-size: $font-12;
  text-align: center;
  color: $secondaryColor;
}

.signUpText {
  font-size: $font-14;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  color: $accentColor;
}
.signUpText:hover {
  text-decoration: none;
  color: $accentHoverColor;
  transition: color 0.2s;
}
@media screen and (max-width: 1200px) {
  .authActionsBackground {
    height: 100vh;
  }
}
