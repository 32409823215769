@import "../../../../assets/scss/variables.scss";

.filterTabBackground {
  height: 25px;
  background-color: $white;
  transition: background-color 0.2s;
  cursor: pointer;

  .svgColor {
    fill: $white;
  }
}

.tabColor {
  font-size: $font-14;
  white-space: nowrap;
  color: $secondaryColor;
}

.filterTabBackground:hover {
  height: 25px;
  background-color: $devDocsLightBlue;
  transition: background-color 0.5s;
  cursor: pointer;

  .tabColor {
    font-family: $outfit;
    font-style: normal;
    font-weight: 400;
    font-size: $d-font-14;
    line-height: 18px;
    /* identical to box height */

    color: $primary-color;
  }
}

.activeTavBackground {
  height: 25px;
  background-color: $devDocsLightBlue;
  transition: background-color 0.5s;
  cursor: pointer;
  border-bottom: 2px solid $primary-color;

  .tabColor {
    font-family: $outfit;
    font-style: normal;
    font-weight: 400;
    font-size: $d-font-14;
    line-height: 18px;
    /* identical to box height */

    color: $primary-color;
  }
}

.activeTavBackground:hover {
  position: relative;
  .svgColor {
    fill: $accentColor;
  }
}

.showCrossMark {
  display: none;
}
