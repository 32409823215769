@import "../../../../assets/scss/variables.scss";

.rotate {
  transform: rotate(-90deg);
}

.backText {
  font-size: $font-14;
  color: $secondaryColor;
}

.cardHeader {
  box-shadow: $CardboxShadow;
  background-color: $cardHeaderColor;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.customPlanCard {
  width: 210px;
  border-radius: 10px;
  box-shadow: $CardboxShadow;
  background-color: $white;
  height: 233px;
}

.planname {
  font-family: $firaSans;
  font-size: $font-14;
  font-weight: 500;
  text-align: left;
  color: $primaryColor;
}

.customPlanText {
  font-size: $font-12;
  text-align: left;
  color: $secondaryColor;
}

.choosePlan {
  font-size: $font-12;
  font-weight: 500;
  text-align: left;
  color: $accentColor;
}

.choosePlan:hover {
  color: $accentHoverColor;
  transition: color 0.2s;
}

.requestInitiated {
  font-size: $font-12;
  text-align: left;
  color: $warning;
  cursor: no-drop;
}

.currentPlan {
  font-size: $font-12;
  text-align: left;
  color: $success;
  cursor: no-drop;
}

.rotateArrowRight {
  margin-top: 3px;
  transform: rotate(-90deg);
}

.compareBillingCard {
  width: 100%;
  border-radius: 10px;
  box-shadow: $CardboxShadow;
  background-color: $white;
}

.tableHeader {
  font-size: $font-14;
  font-weight: 500;
  padding: 20px 15px;
  text-align: left;
  color: $secondaryColor;
}

.tableBody {
  font-size: $font-14;
  padding: 20px 15px;
  text-align: left;
  color: $primaryColor;
  border-bottom: 1px solid $backgroundColor;
}

.tableHeaderWrapper {
  width: 100%;
  background-color: $inputBackgroundColor;
}

td,
th {
  border: 0;
  text-align: left;
}

.highlight {
  background: $cardHeaderColor;
  .tableBody {
    font-size: $font-14;
    text-align: left;
    font-weight: 500;
    color: $primaryColor;
  }
}

.label {
  font-size: $font-12;
  text-align: left;
  color: $secondaryColor;
}

.value {
  font-size: $font-14;
  font-weight: 500;
  text-align: left;
  color: $primaryColor;
}

.longDescription {
  padding: 10px 30px 10px 70px;
  width: 100%;
  height: 100px;
  background: $backgroundColor;
  color: $secondaryColor;
  font-size: $font-14;
}

.expandWrapper,
.nonActiveExpandWrapper {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: transparent;
  cursor: pointer;
}

.expandWrapper:hover {
  background: #ededed;
}

.rotateArrowRightLongDescription {
  transform: rotate(-90deg);
  transition: transform 0.5s;
}

.shadow {
  box-shadow: 0 3px 6px 10px rgba(221, 221, 221, 0.16);
}

.cancelText {
  font-size: $font-12;
  color: $error;
  cursor: pointer;
}

.post_paid_plan_bubble {
  width: 100%;
  padding: 0 10px;
  border-radius: 35px;
  background-color: $accentColor;
  border: 0;
}

.post_paid_plan_text {
  font-size: 12px;
  color: $white;
  text-align: center;
  margin: 0;
  font-weight: normal;
}
