@import "../../../assets/scss/variables.scss";

.authActionsBackground {
  background: $white;
  height: 100vh;
  overflow: auto;
}

.formWidth {
  width: 400px;
  margin: 0 auto;
}
.formBG {
  background: $white;
  border-radius: 10px;
  border: 0.5px solid #dfdfdf;
  position: relative;
  top: -10px;
  margin: 0 10px;
  height: 100%;
  transition: height 2s;
}

.headingText {
  font-family: $firaSans;
  font-size: $font-24;
  font-weight: 500;
  color: $primaryColor;
}

.educationalText {
  font-size: $font-14;
  color: $secondaryColor;
}

.customToggle {
  width: 100%;
  border-radius: 8px;
  background: $white;
  border: 1px solid $inputBackgroundColor;
  cursor: pointer;
  padding: 10px 15px;
  position: relative;
  z-index: 2;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.16);
}

.activeToggle {
  width: 100%;
  border-radius: 8px;
  background: $inputBackgroundColor;
  cursor: pointer;
  padding: 10px 15px;
  position: relative;
  z-index: 2;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.16);
}

.lastCard {
  border-radius: 0 0 10px 10px;
}

.accordion_header {
  font-size: $font-14;
  color: $primaryColor;
}

.linkText {
  font-size: $font-12;
  color: $secondaryColor;
  text-align: center;
}

.rotateArrowRight {
  transform: rotate(-90deg);
  transition: transform 2s;
}

.stepText {
  font-size: $font-14;
  text-align: center;
  color: $secondaryColor;
}

.blockButton {
  height: 40px;
  width: 300px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.blockButton:hover {
  background: rgba($secondaryColor, 0.15);
  border-radius: 8px;
  .linkText {
    font-weight: 500;
  }
}
