@import "../../../../assets/scss/variables.scss";

.headingText {
  font-family: $firaSans;
  font-size: $font-16;
  font-weight: 500;
  text-align: left;
  color: $primaryColor;
}

.background {
  height: 100vh;
  background: $white;
  overflow: auto;
}

.formWidth {
  width: 80%;
}

.formBG {
  background: $white;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  top: -10px;
  border: 0.5px solid rgba(0, 0, 0, 0.2);
}

.accordion_header {
  font-size: $font-14;
  color: $primaryColor;
}

.CardHeading {
  font-size: $font-14;
  text-align: center;
  color: $primaryColor;
}

.rotateArrowRight {
  transform: rotate(-90deg);
}

.customToggle {
  width: 100%;
  border-radius: 8px;
  background: $white;
  border: 1px solid $inputBackgroundColor;
  cursor: pointer;
  padding: 10px 15px;
  position: relative;
  z-index: 1;
}

.activeToggle {
  width: 100%;
  border-radius: 8px;
  background: $inputBackgroundColor;
  cursor: pointer;
  padding: 10px 15px;
  position: relative;
  z-index: 1;

  border: 0.5px solid rgba(0, 0, 0, 0.2);
  border-bottom: none;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.uploadImageText {
  font-size: $font-12;
  text-align: left;
  color: $secondaryColor;
}
.PhotoSVG {
  fill: $primaryColor;
}
.EmptyCard {
  width: 120px;
  height: 120px;
  border-radius: 8px;
  cursor: pointer;
  border: dashed 1px $primaryColor;
  background-color: $white;
}

.EmptyCard:hover {
  border: dashed 1px $accentColor;
  background-color: $linkHoverBackground;
  .PhotoSVG {
    fill: $accentColor;
  }
}

.infoIcon {
  fill: $secondaryColor;
}

.InfoIconBack {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: transparent;
  cursor: default;
}

.InfoIconBack:hover {
  background: $backgroundColor;
  .infoIcon {
    fill: $secondaryColor;
  }
}

.fileType {
  visibility: hidden;
  display: none;
}
.closeSvg {
  display: none;
  fill: $secondaryColor;
  cursor: pointer;
  position: absolute;
  top: -13px;
  right: -12px;
  background-color: transparent;
  border-radius: 50%;
  padding: 8px;
}

.linkText {
  font-size: $font-14;
  color: $accentColor;
  text-align: center;
  cursor: pointer;
}

.linkText:hover {
  color: $accentHoverColor;
  transition: color 0.2s;
}

.uploadImageWrapper {
  position: relative;
  width: 120px;
  height: 120px;
  border-radius: 5px;
  border: 1px solid $secondaryColor;
}

.uploadImageWrapper:hover {
  .closeSvg {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $errorHoverColor;
    fill: $error;
    transition: background-color 0.2s;
  }
  .closeSvg:hover {
    fill: $white;
    background-color: $error;
    transition: background-color 0.2s;
  }
}

.settingHeader {
  width: 60%;
  margin: 0 auto;
}

.settingHeaderText {
  font-size: $font-14;
  line-height: 1.7;
  text-align: center;
  color: $secondaryColor;
}

.iconColor {
  fill: $white;
}

.checkIPAddressBack {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background: $backgroundColor;

  .iconColor {
    fill: $accentColor;
  }
}

.checkIPAddressBack:hover {
  background-color: $accentColor;
  cursor: pointer;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  transition: background-color 0.15s ease-in-out;
  .iconColor {
    fill: $white;
  }
}

.serviceName {
  font-size: $font-12;
  text-align: center;
  color: $accentColor;
}

.addedService {
  border-radius: 50px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  // background-color: $lite-accent-color;
  height: 34px;
  margin-right: 10px;

  background: #ffffff;
  border: 1px solid #0075f3;
  border-radius: 50px;
}

.svgColor {
  fill: $accentColor;
  cursor: pointer;
}

.warningMessageForIPWhitelisting {
  font-size: $font-14;
  text-align: left;
  color: $error;
}

.markedForRemoval {
  font-size: $font-12;
  text-align: left;
  color: $secondaryColor;
}

.removedService {
  border-radius: 50px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: $errorHoverColor;
  height: 34px;
}

.removedServiceText {
  font-size: $font-12;
  text-align: left;
  color: $error;
}

.backText {
  font-size: $font-14;
  color: $secondaryColor;
}

.rotateRight {
  cursor: pointer;
  transform: rotate(-90deg);
}

.IpPlaceholder {
  width: 150px;
  height: 40px;
  border-radius: 50px;
  border: 1px solid #bcbcbc;
}

.addIpAddressButton {
  padding: 10px 15px;
  width: 100px;
  height: 40px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.16);
  background: $accentColor;
}

.addIpAddressButtonText {
  font-size: $font-14;
  color: $white;
  text-align: center;
}

.addIpAddressButton:hover {
  background: $accentHoverColor;
}

.subText {
  font-family: $outfit;
  font-style: normal;
  font-weight: 400;
  font-size: $d-font-14;
  line-height: 18px;
  /* identical to box height */
  margin-top: 5px;

  color: #5b5b5b;
}

.addIpAddrBtn {
  position: absolute;
  right: 103px;
}

.ipWhiteListTitle {
  font-family: $outfit;
  font-style: normal;
  font-weight: 700;
  font-size: $d-font-14;
  line-height: 18px;
  /* identical to box height */

  color: $black-color;
}
