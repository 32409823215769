@import "../../../assets/scss/variables.scss";
.authActionsBackground {
  background: $white;
  height: 100%;
  min-height: 100vh;
  overflow: auto;
  padding: 40px 0 0;
}

.headingText {
  font-family: $firaSans;
  font-size: $font-24;
  font-weight: 500;
  color: $primaryColor;
}

.subText {
  font-size: $font-14;
  text-align: center;
  color: $secondaryColor;
}

.linkText {
  font-size: $font-12;
  color: $secondaryColor;
  text-align: center;
  cursor: pointer;
}

.subscribeLabelText {
  font-size: $font-14;
  color: $success;
  font-weight: 400;
}

.waitingForApprovalLabelText {
  font-size: $font-14;
  color: $warning;
  font-weight: 400;
}

.blockButton {
  height: 40px;
  width: 300px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.blockButton:hover {
  background: rgba($secondaryColor, 0.15);
  border-radius: 8px;
  .linkText {
    font-weight: 500;
  }
}

.serviceLevelSettings {
  width: 100%;
  border-radius: 5px;
  border: solid 1px $secondaryColor;
  background-color: $white;
  margin: 20px auto;
}

.serviceName {
  font-size: $font-14;
  color: $primaryColor;
}

.closeSVG {
  cursor: pointer;
  fill: $secondaryColor;
}

.emptyStateText {
  font-size: $font-14;
  text-align: center;
  color: $secondaryColor;
}

.rotate {
  transform: rotate(-90deg);
}

.backText {
  font-size: $font-14;
  color: $secondaryColor;
}

.noteText {
  font-size: $font-16;
  font-weight: 500;
  text-align: left;
  color: $primaryColor;
}

.noteSubText {
  font-size: $font-14;
  text-align: left;
  color: $secondaryColor;
}

.stepText {
  font-size: $font-14;
  text-align: center;
  color: $secondaryColor;
}
