@import "../../../../assets/scss/variables.scss";
.welcomeText {
  font-size: $font-20;
  font-weight: 600;
  color: $secondaryColor;
}

.headingText {
  font-size: $font-14;
  text-align: center;
  color: $secondaryColor;
}

.noteText {
  font-size: $font-12;
  background-color: $background-white-color;
  text-align: start;
  color: $secondaryColor;
  border-radius: 8px;
}

.transactions {
  font-size: $font-14;
  font-family: 500;
  color: $primaryColor;
}
