@import "../../../assets/scss/variables.scss";

.headingText {
  font-family: $firaSans;
  font-size: $font-16;
  font-weight: 500;
  text-align: left;
  color: $primaryColor;
}

.emptyState {
  height: calc(100vh - 150px);
  width: 100%;
}
.message {
  font-size: $font-16;
  font-family: $firaSans;
  font-weight: 500;
  text-align: center;
  color: $primaryColor;
}
