@import "../../assets/scss/variables.scss";
.container {
  // width: %;
  display: flex;
}

.activePageLink {
  color: gray;
  pointer-events: none;
  text-decoration: none;
}

.pageLink {
  color: gray;
  text-decoration: none;
}

.marker {
  color: gray;
  font-weight: 600;
}

.topNavBackground {
  padding: 0 10px;
  height: 55px;
}

.userSectionBackground {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  background: $accentColor;
  cursor: pointer;
}

.initial {
  font-size: $font-14;
  font-weight: 500;
  color: $white;
}

.SVGColor {
  fill: $text-secondary-color;
  height: 20px;
  width: 20px;
}

.SVGColor:hover {
  fill: $accentColor;
  cursor: pointer;
  text-decoration: none;
}

.SVGNotification {
  fill: $white;
  height: 22px;
  width: 22px;
}

.SVGNotification:hover {
  fill: $accentColor;
  cursor: pointer;
}

.userName {
  font-size: $font-16;
  text-align: left;
  color: $primaryColor;
  word-break: break-all;
}

.userEmail {
  font-size: $font-12;
  text-align: left;
  color: $secondaryColor;
  word-break: break-all;
}

.changePassword {
  font-size: $font-12;
  cursor: pointer;
  color: $primaryColor;
}

.changePasswordBack {
  background: $white;
  cursor: pointer;
  text-decoration: none;
}

.changePasswordBack:hover {
  color: $accentColor;
  font-weight: 600;
  background: $linkHoverBackground;

  .changePassword {
    color: $accentColor;
  }
}

.businessInfo {
  font-size: $font-12;
  cursor: pointer;
  color: $primaryColor;
}

.businessInfoBack {
  background: $white;
  cursor: pointer;
  text-decoration: none;
}

.businessInfoBack:hover {
  color: $accentColor;
  font-weight: 600;
  background: $linkHoverBackground;

  .businessInfo {
    color: $accentColor;
  }
}

.logOut {
  font-size: $font-12;
  cursor: pointer;
  color: $secondaryColor;
}

.logOutBack {
  background: $white;
  cursor: pointer;
  text-decoration: none;
}

.logOutBack:hover {
  color: $error;
  font-weight: 600;
  background: rgba($error, 0.1);

  .logOut {
    color: $error;
  }
}

.divider {
  margin: 0 0 0 0;
}

.logo {
  height: 35px;
}

.sidebarWrapper {
  position: fixed;
  height: calc(100vh - 65px);
  top: 65px;
  left: 0;
  animation: slide 0.2s ease-in forwards;
}

.showOnMobileDevices {
  display: none;
}

.arrowWrapper {
  width: 25px;
  height: 25px;
  border-radius: 4px;
  background: $white;
  cursor: pointer;
}

.rotateArrowRight {
  transform: rotate(-90deg);
}

@media screen and (max-width: 1200px) {
  .showOnMobileDevices {
    display: block;
  }

  .logo {
    height: 25px;
  }
}

@keyframes slide {
  from {
    left: -100px;
  }

  to {
    left: 0;
  }
}

.docText {
  font-size: $font-14;
  color: $text-secondary-color;
  font-weight: 400;
}

.navPageName {
  margin-left: 35px;
}

.rotate {
  transform: rotate(-90deg);
}

.backText {
  font-weight: 500;
  font-size: $d-font-16;
  line-height: 23px;
  display: flex;
  align-items: center;
  margin-left: 0.1em;

  color: $text-secondary-color;
}
