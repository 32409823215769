@import "../../../assets/scss/variables.scss";

.authActionsBackground {
  background: $white;
  height: 100vh;
  overflow: auto;
}

.formWidth {
  width: 400px;
}

.headingText {
  font-family: $firaSans;
  font-size: $font-24;
  font-weight: 500;
  color: $primaryColor;
}

.educationalText {
  font-size: $font-14;
  color: $secondaryColor;
}

.customToggle {
  width: 100%;
  border-radius: 8px;
  background: $white;
  border: 1px solid $inputBackgroundColor;
  cursor: pointer;
  padding: 10px 15px;
  position: relative;
  z-index: 2;
}

.activeToggle {
  width: 100%;
  border-radius: 8px;
  background: $inputBackgroundColor;
  cursor: pointer;
  padding: 10px 15px;
  position: relative;
  z-index: 2;
}

.accordion_header {
  font-size: $font-14;
  color: $primaryColor;
}

.rotateArrowRight {
  transform: rotate(-90deg);
  transition: transform 0.5s;
}
