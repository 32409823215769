@import "../assets/scss/variables.scss";

.formLabel {
  text-align: left;

  font-family: $outfit;
  font-style: normal;
  font-weight: 500;
  font-size: $d-font-12;
  line-height: 15px;

  color: $black-color;
}

.commonInputClass {
  display: block;
  width: 100%;
  padding: 15px 17px;
  font-size: $font-14;
  font-weight: 400;
  border-radius: 2px;
  line-height: 1.5;
  color: $primaryColor;
  background: $inputBackgroundColor;
}

.formControl {
  // box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.16);
  // transition: box-shadow 0.3s ease-in-out;
  border: 1px solid #e8e8e8;
}

.error {
  border: 1px solid #e8e8e8;
  // box-shadow: 0 3px 10px 0 rgba(214, 71, 71, 0.16);
  // transition: box-shadow 0.3s ease-in-out;
}

.valid {
  border: 1px solid #e8e8e8;
  // box-shadow: 0 3px 10px 0 rgba(0, 117, 243, 0.16);
  // transition: box-shadow 0.3s ease-in-out;
}

.formControl::-moz-placeholder {
  font-size: $font-12;
  text-align: left;
  color: $secondaryColor;
}

.formControl::placeholder {
  font-size: $font-12;
  text-align: left;
  color: $secondaryColor;
}

.error::-moz-placeholder {
  font-size: $font-12;
  text-align: left;
  color: $secondaryColor;
}

.error::placeholder {
  font-size: $font-12;
  text-align: left;
  color: $secondaryColor;
}

.formControl:focus {
  outline: none;
  // box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  // transition: box-shadow 0.15s ease-in-out;
}

.valid:focus {
  outline: none;
  // box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  // transition: box-shadow 0.15s ease-in-out;
}

.error:focus {
  outline: none;
  // box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  // transition: box-shadow 0.15s ease-in-out;
}

.formControl:disabled {
  opacity: 0.5;
  cursor: no-drop;
}
