@import "../../assets/scss/variables.scss";

.toastPosition {
  width: 100%;
  max-width: 500px;
  position: fixed;
  top: 70px;
  right: 20px;
  border-radius: 10px;
  box-shadow: 0 10px 25px 0 rgba(0, 0, 0, 0.15);
  padding: 20px;
  z-index: 10;
  background: $white;
  animation: slide 0.3s forwards;
}

.iconBackground {
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.errorSvgColor {
  fill: $white;
}

.messageText {
  font-size: $font-14;
  font-weight: 500;
  text-align: left;
  color: $secondaryColor;
}

.overAllCrossSvg {
  fill: $secondaryColor;
  cursor: pointer;
}

@keyframes slide {
  from {
    right: -500px;
  }
  to {
    right: 20px;
  }
}
