@import "../../../../assets/scss/variables.scss";

.card {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background: $white;
  box-shadow: $CardboxShadow;
  margin-top: 0;

  border: 1px solid #dddddd;
}

.cardAnimation {
  animation: $annimationName 0.5s;
}

.appLogo {
  display: flex;
  background-color: #e8e8e8;
  cursor: pointer;
  margin: 0 auto;
  width: 60px;
  height: 60px;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  position: relative;
}

.env {
  position: absolute;
  bottom: -5px;
  width: 100%;
  border-radius: 36px;

  .env_text {
    font-size: 10px;
    text-align: center;
    color: $white;
  }
}

.appInitial {
  font-size: 24px;
  text-align: center;
  color: $primaryColor;
}

.appName {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  text-align: center;
  cursor: default;

  // font-family: $outfit;
  // font-style: normal;
  // font-weight: 600;
  // font-size: $d-font-18;
  // line-height: 28px;
  // /* identical to box height */

  // color: $black-color;
}

.createdAt {
  font-family: $outfit;
  font-style: normal;
  font-weight: 400;
  font-size: $d-font-12;
  line-height: 15px;
  text-align: center;

  color: #454545;
}

.sectionHeader {
  text-align: center;
  font-family: $outfit;
  font-style: normal;
  font-weight: 400;
  font-size: $d-font-14;
  line-height: 18px;
  /* identical to box height */

  color: $devDocsDarkGray;
}

.sectionValue {
  text-align: center;
  font-family: $outfit;
  font-style: normal;
  font-weight: 600;
  font-size: $d-font-16;
  line-height: 20px;

  color: $black-color;
}

.moreInfoText {
  font-size: $font-14;
  font-weight: 600;
  color: #2d87ee;
  cursor: pointer;
}

.moreInfoText:hover {
  color: linear-gradient(87.01deg, #22a8c0 0%, #2d87ee 100%);
  transition: color 0.2s;
  text-decoration: none;
}

.tryNowButton {
  background: linear-gradient(87.01deg, #22a8c0 0%, #2d87ee 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  width: 100px;
  height: 40px;
  padding: 5px;
  font-weight: 600;
  font-size: $font-14;
  outline: none;
  border: none;
  border-radius: 4px;
  margin-left: -20px;

  &:hover {
    text-decoration: none;
    color: white;
  }
}

@media screen and (max-width: 1024px) {
  .card {
    width: 100%;
  }
}

.buttonContainer {
  width: 100%;
}

.moreInfoBtn {
  width: 100px !important;
}
