@import "../../../assets//scss/variables.scss";

* {
  font-family: "poppins" sans-serif;
}

.container {
  width: 100%;
}
.overallHeadingText {
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
}

.headingText {
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
}

.serviceName {
  font-weight: 600;
  font-size: 22px;
  line-height: 33px;
}

.navTabs {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: gray;
}
