@import "../../../assets/scss/variables.scss";

.headingText {
  text-align: left;
  color: $primaryColor;

  font-family: $poppins;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 36px;
}

.dropdown {
  width: 189px;
  height: 56px;
}

.inputWrapper {
  border: 1px solid var(--Content-Grey, #969696);
  border-radius: 8px;
}

.formControl {
  display: block;
  width: 100%;
  padding: 10px 5px;
  font-size: $font-14;
  font-weight: 400;
  line-height: 1.5;
  color: $primaryColor;
  background: transparent;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.formControl::placeholder {
  font-size: $font-12;
  color: $secondaryColor;
}

.formControl:focus {
  outline: none;
  box-shadow: none;
}

.formLabel {
  font-family: $outfit;
  font-style: normal;
  font-weight: 500;
  font-size: $d-font-12;
  line-height: 15px;
  color: $black-color;
}
/* Add Batch Styling*/

.addBatchTitle {
  font-family: $poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 45px;
  margin-bottom: 39px;

  color: $modalTitleColor;
}

.crossBtn {
  justify-content: flex-end;
  display: flex;
  margin-right: 27px;
  margin-top: 26px;
  cursor: pointer;
}

.modal-content {
  max-width: 680px !important;
}

.modalBody {
  padding-right: 8% !important;
  padding-left: 8% !important;
  padding-top: 3% !important;
  border-radius: 20px !important;
}

.uploadFile {
  font-family: $outfit;
  font-style: normal;
  font-weight: 500;
  font-size: $d-font-12;
  line-height: 15px;

  color: $black-color;
}

.instructionText {
  font-family: $outfit;
  font-style: normal;
  font-weight: 400;
  font-size: $d-font-12;
  line-height: 18px;
  margin-top: 10px;
  /* identical to box height */

  color: $text-secondary-color;
}

.downloadFile {
  font-family: $poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  text-decoration-line: underline;
  cursor: pointer;
  margin-bottom: 1rem;

  color: $accentColor;
}

.chooseFile {
  margin-top: 0.5rem;

  background: $white 0% 0% no-repeat padding-box;
  border: 1px dashed $text-secondary-color;
  opacity: 1;
  position: relative;
  z-index: 999;

  border-radius: 10px;
}

.selectFiletxt {
  font-family: $outfit;
  font-style: normal;
  font-weight: 600;
  font-size: $d-font-22;
  line-height: 28px;
  /* identical to box height */

  color: $text-secondary-color;
}

.selectFileOrTxt {
  font-family: $outfit;
  font-style: normal;
  font-weight: 400;
  font-size: $d-font-14;
  line-height: 18px;
  /* identical to box height */

  color: $text-secondary-color;
}

.uploadedContainer {
  border: 1px solid #d6d6d6;
  border-radius: 8px;
  opacity: 1;
  height: 41px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 27px;
  padding-right: 15px;
  span {
    font-family: $poppins;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
    max-width: 70%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    /* identical to box height */

    text-decoration-line: underline;

    color: #484848;
  }
  p {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
    cursor: pointer;
    /* identical to box height */

    color: #db4437;
  }
}

.fileUploaded {
  margin-top: 1.5rem;
}

.refreshContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px;
  gap: 10px;

  width: 48px;
  height: 41px;

  background: #e4f1ff;
  border-radius: 8px;
  cursor: pointer;
}

.refreshContainer:hover {
  background: #c8ddf3;
}

.inputDropdown {
  border-radius: 10px;
}

.filterBorder {
  width: 100%;
  height: 100%;

  border-radius: 16px;
  border: 1px solid #dddcdd;
  // margin-left: 20px;
}

.filterTrackTask {
  display: flex;
  // justify-content: flex-start;
  // flex-wrap: wrap;
  // gap: 100px;
}

.dropdown2 {
  width: 26%;
  margin-bottom: 8px;
  margin: 25px;
  // margin-left: 30px;
}
.customDatePicker {
  margin-top: 27px;
  margin-left: 30px;
  margin-right: 20px;
}
.filterButtonclearButton {
  width: 6%;
  margin-bottom: 8px;
  //  margin: 20px;
  margin-left: 25px;
}

@media only screen and (max-width: 451px) {
  .borderfilter {
    width: auto;
    height: auto;
  }
  .filterBorder {
    width: auto;
    height: 600px;
    padding: auto;
    padding-right: auto;
  }
  .dropdown2 {
    width: auto;
    margin-bottom: auto;
    padding-top: auto;
    margin: 10px;
  }
  .filterButtonclearButton {
    margin-bottom: auto;
    padding-top: auto;
    margin-top: 20px;
  }
  .datePicker {
    flex-wrap: wrap;
  }
}
@media only screen and (max-width: 350px) {
  .filterBorder {
    width: auto;
    height: 600px;
    padding-right: auto;
    overflow: auto;
  }
  .dropdown2 {
    width: auto;
    margin-bottom: auto;
  }
  .filterButtonclearButton {
    // margin-bottom: auto;
    width: auto;
    margin-top: auto;
  }
  .datePicker {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 450px) {
  .filterButtonclearButton {
    margin-bottom: auto;
    width: auto;
    margin-top: 10px;
  }

  .dropdown2 {
    width: auto;
    margin-bottom: auto;
    padding-top: auto;
    margin-left: 22px;
  }
  .filterBorder {
    width: auto;
    height: 600px;
    padding-right: auto;
  }
}
@media only screen and (max-width: 900px) {
  .filterButtonclearButton {
    margin-bottom: auto;
    width: auto;
    margin-top: 10px;
  }

  .filterBorder {
    width: 100%;
    height: 300px;
    padding-right: auto;
    box-sizing: border-box;
  }
}

.datePicker {
  display: flex;
}

@media only screen and (max-width: 1300px) {
  .filterButtonclearButton {
    // margin-bottom: auto;
    width: auto;
    margin-top: 20px;
  }
}


@media only screen and (max-width: 813px) {
  .filterButtonclearButton {
    // margin-bottom: auto;
    width: auto;
    margin-top: 20px;
  }

  .dropdown2 {
    width: 100%;
    margin-bottom: auto;
    padding-top: auto;
    margin-left: 22px;
  }
  .customDatePicker {
    margin-left: 10px;
    // width: 100%;
  }
  .formLabel {
    margin-left: 16px;
  }
  .filterBorder {
    width: 100%;
    padding-right: auto;
    height: auto;
    // margin-top: 30px;
  }
}
@media only screen and (max-width: 614px) {
  .filterBorder {
    width: 100%;
    height: auto;
    padding-right: auto;
  }
  .dropdown2 {
    width: 100%;
    margin-bottom: auto;
    padding-top: auto;
    margin-left: 22px;
  }
  .filterButtonclearButton {
    margin-bottom: auto;
    width: auto;
    margin-top: 40px;
  }
}
.batchIdText {
  color: #747474;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
}

.batchIdTitle {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0px;
  text-align: left;
  padding-left: 25px;
  // margin-left: 4px;
}

.taskCountTable {
  background-color: #ededed;
  display: flex;
  padding: 23px 49px 10px 38px;
  border-radius: 10px 10px 0px 0px;
  justify-content: space-around;
}

.tableHead {
  color: #111928;
  font: 500 16px/162.5% Outfit, -apple-system, Roboto, Helvetica, sans-serif;
}

.trackTaskFilterDiv {
  display: flex;
  gap: 10px;
}
.taskCountTableValue {
  border-bottom: 1px solid #f7f7f7;
  background-color: #fff;
  display: flex;
  // width: 100%;
  justify-content: space-around;
  padding: 23px 49px 10px 38px;
}

.deleteButtonDiv {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 1000px;
  position: sticky;
}

.deleteButton {
  border-radius: 8px;
  border: 1px solid var(--Red, #db4437);
  display: flex;
  gap: 10px;
  padding: 10px 24px;
  width: 165px;
  cursor: pointer;
}
.deleteButton2 {
  color: var(--Red, #db4437);
  white-space: nowrap;
  font: 600 14px Poppins, sans-serif;
}

.headingStyle {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0px;
  //  margin-left: 45px;
  margin-bottom: 10px;
}

.roatate90 {
  transform: rotate(90deg);
}
